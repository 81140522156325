.form-control{
	padding-bottom: 0.3rem;
}


.form-group{
	&.captcha{
		label, #Login1_RadCaptcha1_ctl00, #RadCaptcha2_ctl00, #RadCaptcha1_ctl00{
			display: none !important;
		}
		img, #Login1_RadCaptcha1_ctl01, p, #RadCaptcha2_ctl01, #RadCaptcha1_ctl01{
			display: inline-block !important;
			vertical-align: top;
		}
		img{
			margin-right: 24px;
		}
		a.rcRefreshImage{
			font-size: 0.7rem;
		}
		input{
			border: 0;
			background: transparent;
			font-weight: 100;
			color: #404040;
			width: 210px;
			font-family: 'Roboto', sans-serif;
			border-bottom: 2px solid $brand-primary;
			&:focus{
				outline: 0;
			}
		}
	}
}

select.form-control{
	cursor: pointer;
	border: 0;
	box-shadow: none;
	border-bottom: 1px solid rgba(51, 51, 51, 0.15);
	@include transition(all .3s);
	padding: 0.5rem;
	-moz-appearance: none;
	background-image: url('/app/images/down-arrow.png');
	background-repeat: no-repeat;
	background-position: 98% 50%;
	&:focus{
	    border-bottom: 1px solid #20409a;
	    -webkit-box-shadow: 0 1px 0 0 #20409a;
	    box-shadow: 0 1px 0 0 #20409a;
	    background-image: url('/app/images/up-arrow.png');
	    background-size: 20px 11px;
	    background-repeat: no-repeat;
	    background-position: 98% 50%;
	}
	&::-ms-expand {
	    display: none;
	}

}


.md-form{
	.prefix{
		font-size: 1.2rem;
		color: $secondary-color;
		top: 50%;
		@include transform(translateY(-50%));
		& ~ input, & ~ select, & ~ textarea{
			margin-left: 2rem;
			width: calc(100% - 2rem);
		}
		& ~ label{
			margin-left: 2rem;
		}
	}
	label{
		top: 1rem;
		margin-bottom: 0 !important;
		@include transform-origin(0 0);
	}
	&.has-value, &.focus{
		label{
			@include transform(scale(0.75) translateY(-30px));
			
		}
	}
	textarea{
		border: none;
		border-bottom: 1px solid rgba(51, 51, 51, 0.15);
		resize: none;
		box-shadow: none;
	}
	span{
	    position: absolute;
	    top: 0;
	    right: 0;
	    font-size: 0.8rem;
	    color: #90a4ae;
	    font-weight: 600;
	    .account-settings &{
	    	position: relative;
	    	padding: 0 0;
	    	font-size: 1rem;
	    	color: $black;
	    }
	}

	&.select-cont{
		label{
			top: 0.5rem;
		}
	}


}

.failureNotification{
	background: rgba($secondary-color,0.2);
	margin-top: 10px;
	color: $black;
	padding: 15px;
	font-size: 0.9rem;
	ul{
		margin-bottom: 0;
	}
}



.rddlInner, 
.riTextBox 
{
	font-weight: 600 !important;
}

.gender{
	label{
		display: block;
	}
}

.dropdown-blue{
	background: $brand-primary;
	color: $white;
}