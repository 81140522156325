.tabs-cont-analytics{
	padding-top: 25px;
	.title{
		font-weight: 600;
		color: $brand-primary;
	}
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 10px;
		li{
			a{
				&.block-rounded{
					padding: 1.67rem;
				}
				&.active{
					background: $brand-primary;
					@include linear-gradient(to left, $brand-primary-light, darken($brand-primary,5%));
					color: $white;
				}
			}
		}
	}
}
.date-analytics{
	margin-bottom: 15px;
	color: $brand-primary;
	strong{
		font-weight: 600;
	}
}
.analytics-results{
	h3{
		font-weight: 600;
		color: $brand-primary
	}
	h2{
		font-size: 1.5rem;
	}
}