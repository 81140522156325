.block-rounded{
	padding: 1.5rem;
	@include media-breakpoint-down(lg){
		padding: 1.2rem;
	}
}

.form-inline{
	.RadInput_Material{
		padding-left: 0;
		padding-right: 0;
	}
	.RadComboBox{
		margin-left: 5px;
	}
}


.RadEditor{
	max-width: 100% !important;
}


.RadButton_Material.rbToggleButton .rbIcon:before{
	overflow: visible !important;
	line-height: 1.3rem !important;
}

.RadGrid_Material .rgGroupPanel{
	padding: 5px 15px !important;
}
table, .RadGrid_Material{
	&:focus{
		outline: 0 !important;
	}
}
.rgNoRecords{
	padding: 15px;
	display: block;
}
.RadSlider_Material .rslTrack a.rslDraghandle:after,
.RadSlider_Material .rslSelectedregion{
	background-color: $brand-primary !important;
}


.rddlPopup_Material .rddlItemSelected,
.RadButton_Material .rbToggleCheckboxChecked, .RadButton_Material .rbToggleCheckboxFilled, .RadButton_Material.rbCheckBox .rbToggleCheckboxChecked.rbIcon, .RadButton_Material.rbRadioButton .rbToggleRadioChecked.rbIcon, .RadButton_Material.rbToggled .rbToggleCheckBoxChecked.rbIcon, .RadButton_Material.rbToggled .rbToggleRadioChecked.rbIcon, .RadButton_Material .rbText.rbToggleCheckboxChecked:before, .RadButton_Material .rbText.rbToggleRadioChecked:before, .RadButton_Material .rbText.rbToggleCheckbox .rbRippleContainer, .RadButton_Material .rbText.rbToggleRadio .rbRippleContainer
{
	color: $brand-primary !important;
}

.RadButton{
	font:  1rem $primary-font !important;
}

.k-chart-tooltip{
	background-image: none !important;
	border-radius: 999rem !important;
	font-family: $primary-font !important;
    width: 30px !important;
    height: 30px !important;
    text-align: center !important;
    font-weight: 100!important;
    line-height: 17px !important;
    text-indent: -1px;
}