.summary__block{
	position: relative;
}
.diagnostic-cont{
    width: 100%;
    max-width: 850px;
    margin: 0 auto;

	.question-answers{
		padding: 0 0;




		.inner{
			padding: 40px;
			@include media-breakpoint-down(lg){
				padding: 25px;
			}
		}


		.answer-show{
			margin-bottom: 40px;
			.inner{
			    padding: 0.5rem 1rem;
			    position: absolute;
			    color: $white;
			    font-size: 0.89rem;
			    border-radius: 0.5rem;
			    background: $secondary-color;
			}
		}

		.instruction{
			padding-bottom: 0;
		}
		.intro__title{
			color: $brand-primary;
			margin-bottom: 15px;
		}
		.title__question{
			margin-bottom: 15px;
			color: $brand-primary;
		    margin-bottom: 15px;
		    font-size: 1.3rem;
		    text-align: right;
			@include media-breakpoint-down(lg){
				font-size: 1.1rem;
			}

		}
		.title__part{
			color: $black;
			font-weight: 400;
			font-size: 1.05rem;
			margin-bottom: 20px;
			line-height: 1.7;
			font-family: $primary-font !important;
			@include media-breakpoint-down(lg){
				font-size: 0.95rem;
			}
			.number{
				padding-right: 10px;
			}
		}
		.options__answ{
			margin-bottom: 0;

			li[data-slider=next]{
				font-size: 1.25rem;
				display: block;
				font-weight: 600;
				margin: 1vw 0;
				cursor: pointer;
				@extend %transition;
				color: $brand-primary;
				position: relative;

				@include media-breakpoint-down(lg){
					font-size: 1.1rem;
				}

				&:before{
					content: '';
					position: absolute;
					top: 50%;
					left: -15px;
					width: 0;
					height: 1px;
					background: $secondary-color;
					@extend %transition;
				}
				&:hover{
					padding-left: 5px;
					color: $secondary-color;
					&:before{
						width: 18px;
					}
				}
				&.checked{
					padding-left: 5px;
					color: $secondary-color;
					&:before{
						width: 18px;
					}
				}
			}
		}

	}
}

.sticky-wrapper{
	-webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    @include media-breakpoint-down(lg){
    	height: auto !important;
    }
}

