$fa-font-path:        "/fonts" !default;
$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
//$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default; // for referencing Bootstrap CDN font files directly
$fa-css-prefix:       fa !default;
$fa-version:          "4.7.0" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:         (30em / 14) !default;

$fa-var-500px: "\f26e";
$fa-var-address-book: "\f2b9";
$fa-var-address-book-o: "\f2ba";
$fa-var-address-card: "\f2bb";
$fa-var-address-card-o: "\f2bc";
$fa-var-adjust: "\f042";
$fa-var-adn: "\f170";
$fa-var-align-center: "\f037";
$fa-var-align-justify: "\f039";
$fa-var-align-left: "\f036";
$fa-var-align-right: "\f038";
$fa-var-amazon: "\f270";
$fa-var-ambulance: "\f0f9";
$fa-var-american-sign-language-interpreting: "\f2a3";
$fa-var-anchor: "\f13d";
$fa-var-android: "\f17b";
$fa-var-angellist: "\f209";
$fa-var-angle-double-down: "\f103";
$fa-var-angle-double-left: "\f100";
$fa-var-angle-double-right: "\f101";
$fa-var-angle-double-up: "\f102";
$fa-var-angle-down: "\f107";
$fa-var-angle-left: "\f104";
$fa-var-angle-right: "\f105";
$fa-var-angle-up: "\f106";
$fa-var-apple: "\f179";
$fa-var-archive: "\f187";
$fa-var-area-chart: "\f1fe";
$fa-var-arrow-circle-down: "\f0ab";
$fa-var-arrow-circle-left: "\f0a8";
$fa-var-arrow-circle-o-down: "\f01a";
$fa-var-arrow-circle-o-left: "\f190";
$fa-var-arrow-circle-o-right: "\f18e";
$fa-var-arrow-circle-o-up: "\f01b";
$fa-var-arrow-circle-right: "\f0a9";
$fa-var-arrow-circle-up: "\f0aa";
$fa-var-arrow-down: "\f063";
$fa-var-arrow-left: "\f060";
$fa-var-arrow-right: "\f061";
$fa-var-arrow-up: "\f062";
$fa-var-arrows: "\f047";
$fa-var-arrows-alt: "\f0b2";
$fa-var-arrows-h: "\f07e";
$fa-var-arrows-v: "\f07d";
$fa-var-asl-interpreting: "\f2a3";
$fa-var-assistive-listening-systems: "\f2a2";
$fa-var-asterisk: "\f069";
$fa-var-at: "\f1fa";
$fa-var-audio-description: "\f29e";
$fa-var-automobile: "\f1b9";
$fa-var-backward: "\f04a";
$fa-var-balance-scale: "\f24e";
$fa-var-ban: "\f05e";
$fa-var-bandcamp: "\f2d5";
$fa-var-bank: "\f19c";
$fa-var-bar-chart: "\f080";
$fa-var-bar-chart-o: "\f080";
$fa-var-barcode: "\f02a";
$fa-var-bars: "\f0c9";
$fa-var-bath: "\f2cd";
$fa-var-bathtub: "\f2cd";
$fa-var-battery: "\f240";
$fa-var-battery-0: "\f244";
$fa-var-battery-1: "\f243";
$fa-var-battery-2: "\f242";
$fa-var-battery-3: "\f241";
$fa-var-battery-4: "\f240";
$fa-var-battery-empty: "\f244";
$fa-var-battery-full: "\f240";
$fa-var-battery-half: "\f242";
$fa-var-battery-quarter: "\f243";
$fa-var-battery-three-quarters: "\f241";
$fa-var-bed: "\f236";
$fa-var-beer: "\f0fc";
$fa-var-behance: "\f1b4";
$fa-var-behance-square: "\f1b5";
$fa-var-bell: "\f0f3";
$fa-var-bell-o: "\f0a2";
$fa-var-bell-slash: "\f1f6";
$fa-var-bell-slash-o: "\f1f7";
$fa-var-bicycle: "\f206";
$fa-var-binoculars: "\f1e5";
$fa-var-birthday-cake: "\f1fd";
$fa-var-bitbucket: "\f171";
$fa-var-bitbucket-square: "\f172";
$fa-var-bitcoin: "\f15a";
$fa-var-black-tie: "\f27e";
$fa-var-blind: "\f29d";
$fa-var-bluetooth: "\f293";
$fa-var-bluetooth-b: "\f294";
$fa-var-bold: "\f032";
$fa-var-bolt: "\f0e7";
$fa-var-bomb: "\f1e2";
$fa-var-book: "\f02d";
$fa-var-bookmark: "\f02e";
$fa-var-bookmark-o: "\f097";
$fa-var-braille: "\f2a1";
$fa-var-briefcase: "\f0b1";
$fa-var-btc: "\f15a";
$fa-var-bug: "\f188";
$fa-var-building: "\f1ad";
$fa-var-building-o: "\f0f7";
$fa-var-bullhorn: "\f0a1";
$fa-var-bullseye: "\f140";
$fa-var-bus: "\f207";
$fa-var-buysellads: "\f20d";
$fa-var-cab: "\f1ba";
$fa-var-calculator: "\f1ec";
$fa-var-calendar: "\f073";
$fa-var-calendar-check-o: "\f274";
$fa-var-calendar-minus-o: "\f272";
$fa-var-calendar-o: "\f133";
$fa-var-calendar-plus-o: "\f271";
$fa-var-calendar-times-o: "\f273";
$fa-var-camera: "\f030";
$fa-var-camera-retro: "\f083";
$fa-var-car: "\f1b9";
$fa-var-caret-down: "\f0d7";
$fa-var-caret-left: "\f0d9";
$fa-var-caret-right: "\f0da";
$fa-var-caret-square-o-down: "\f150";
$fa-var-caret-square-o-left: "\f191";
$fa-var-caret-square-o-right: "\f152";
$fa-var-caret-square-o-up: "\f151";
$fa-var-caret-up: "\f0d8";
$fa-var-cart-arrow-down: "\f218";
$fa-var-cart-plus: "\f217";
$fa-var-cc: "\f20a";
$fa-var-cc-amex: "\f1f3";
$fa-var-cc-diners-club: "\f24c";
$fa-var-cc-discover: "\f1f2";
$fa-var-cc-jcb: "\f24b";
$fa-var-cc-mastercard: "\f1f1";
$fa-var-cc-paypal: "\f1f4";
$fa-var-cc-stripe: "\f1f5";
$fa-var-cc-visa: "\f1f0";
$fa-var-certificate: "\f0a3";
$fa-var-chain: "\f0c1";
$fa-var-chain-broken: "\f127";
$fa-var-check: "\f00c";
$fa-var-check-circle: "\f058";
$fa-var-check-circle-o: "\f05d";
$fa-var-check-square: "\f14a";
$fa-var-check-square-o: "\f046";
$fa-var-chevron-circle-down: "\f13a";
$fa-var-chevron-circle-left: "\f137";
$fa-var-chevron-circle-right: "\f138";
$fa-var-chevron-circle-up: "\f139";
$fa-var-chevron-down: "\f078";
$fa-var-chevron-left: "\f053";
$fa-var-chevron-right: "\f054";
$fa-var-chevron-up: "\f077";
$fa-var-child: "\f1ae";
$fa-var-chrome: "\f268";
$fa-var-circle: "\f111";
$fa-var-circle-o: "\f10c";
$fa-var-circle-o-notch: "\f1ce";
$fa-var-circle-thin: "\f1db";
$fa-var-clipboard: "\f0ea";
$fa-var-clock-o: "\f017";
$fa-var-clone: "\f24d";
$fa-var-close: "\f00d";
$fa-var-cloud: "\f0c2";
$fa-var-cloud-download: "\f0ed";
$fa-var-cloud-upload: "\f0ee";
$fa-var-cny: "\f157";
$fa-var-code: "\f121";
$fa-var-code-fork: "\f126";
$fa-var-codepen: "\f1cb";
$fa-var-codiepie: "\f284";
$fa-var-coffee: "\f0f4";
$fa-var-cog: "\f013";
$fa-var-cogs: "\f085";
$fa-var-columns: "\f0db";
$fa-var-comment: "\f075";
$fa-var-comment-o: "\f0e5";
$fa-var-commenting: "\f27a";
$fa-var-commenting-o: "\f27b";
$fa-var-comments: "\f086";
$fa-var-comments-o: "\f0e6";
$fa-var-compass: "\f14e";
$fa-var-compress: "\f066";
$fa-var-connectdevelop: "\f20e";
$fa-var-contao: "\f26d";
$fa-var-copy: "\f0c5";
$fa-var-copyright: "\f1f9";
$fa-var-creative-commons: "\f25e";
$fa-var-credit-card: "\f09d";
$fa-var-credit-card-alt: "\f283";
$fa-var-crop: "\f125";
$fa-var-crosshairs: "\f05b";
$fa-var-css3: "\f13c";
$fa-var-cube: "\f1b2";
$fa-var-cubes: "\f1b3";
$fa-var-cut: "\f0c4";
$fa-var-cutlery: "\f0f5";
$fa-var-dashboard: "\f0e4";
$fa-var-dashcube: "\f210";
$fa-var-database: "\f1c0";
$fa-var-deaf: "\f2a4";
$fa-var-deafness: "\f2a4";
$fa-var-dedent: "\f03b";
$fa-var-delicious: "\f1a5";
$fa-var-desktop: "\f108";
$fa-var-deviantart: "\f1bd";
$fa-var-diamond: "\f219";
$fa-var-digg: "\f1a6";
$fa-var-dollar: "\f155";
$fa-var-dot-circle-o: "\f192";
$fa-var-download: "\f019";
$fa-var-dribbble: "\f17d";
$fa-var-drivers-license: "\f2c2";
$fa-var-drivers-license-o: "\f2c3";
$fa-var-dropbox: "\f16b";
$fa-var-drupal: "\f1a9";
$fa-var-edge: "\f282";
$fa-var-edit: "\f044";
$fa-var-eercast: "\f2da";
$fa-var-eject: "\f052";
$fa-var-ellipsis-h: "\f141";
$fa-var-ellipsis-v: "\f142";
$fa-var-empire: "\f1d1";
$fa-var-envelope: "\f0e0";
$fa-var-envelope-o: "\f003";
$fa-var-envelope-open: "\f2b6";
$fa-var-envelope-open-o: "\f2b7";
$fa-var-envelope-square: "\f199";
$fa-var-envira: "\f299";
$fa-var-eraser: "\f12d";
$fa-var-etsy: "\f2d7";
$fa-var-eur: "\f153";
$fa-var-euro: "\f153";
$fa-var-exchange: "\f0ec";
$fa-var-exclamation: "\f12a";
$fa-var-exclamation-circle: "\f06a";
$fa-var-exclamation-triangle: "\f071";
$fa-var-expand: "\f065";
$fa-var-expeditedssl: "\f23e";
$fa-var-external-link: "\f08e";
$fa-var-external-link-square: "\f14c";
$fa-var-eye: "\f06e";
$fa-var-eye-slash: "\f070";
$fa-var-eyedropper: "\f1fb";
$fa-var-fa: "\f2b4";
$fa-var-facebook: "\f09a";
$fa-var-facebook-f: "\f09a";
$fa-var-facebook-official: "\f230";
$fa-var-facebook-square: "\f082";
$fa-var-fast-backward: "\f049";
$fa-var-fast-forward: "\f050";
$fa-var-fax: "\f1ac";
$fa-var-feed: "\f09e";
$fa-var-female: "\f182";
$fa-var-fighter-jet: "\f0fb";
$fa-var-file: "\f15b";
$fa-var-file-archive-o: "\f1c6";
$fa-var-file-audio-o: "\f1c7";
$fa-var-file-code-o: "\f1c9";
$fa-var-file-excel-o: "\f1c3";
$fa-var-file-image-o: "\f1c5";
$fa-var-file-movie-o: "\f1c8";
$fa-var-file-o: "\f016";
$fa-var-file-pdf-o: "\f1c1";
$fa-var-file-photo-o: "\f1c5";
$fa-var-file-picture-o: "\f1c5";
$fa-var-file-powerpoint-o: "\f1c4";
$fa-var-file-sound-o: "\f1c7";
$fa-var-file-text: "\f15c";
$fa-var-file-text-o: "\f0f6";
$fa-var-file-video-o: "\f1c8";
$fa-var-file-word-o: "\f1c2";
$fa-var-file-zip-o: "\f1c6";
$fa-var-files-o: "\f0c5";
$fa-var-film: "\f008";
$fa-var-filter: "\f0b0";
$fa-var-fire: "\f06d";
$fa-var-fire-extinguisher: "\f134";
$fa-var-firefox: "\f269";
$fa-var-first-order: "\f2b0";
$fa-var-flag: "\f024";
$fa-var-flag-checkered: "\f11e";
$fa-var-flag-o: "\f11d";
$fa-var-flash: "\f0e7";
$fa-var-flask: "\f0c3";
$fa-var-flickr: "\f16e";
$fa-var-floppy-o: "\f0c7";
$fa-var-folder: "\f07b";
$fa-var-folder-o: "\f114";
$fa-var-folder-open: "\f07c";
$fa-var-folder-open-o: "\f115";
$fa-var-font: "\f031";
$fa-var-font-awesome: "\f2b4";
$fa-var-fonticons: "\f280";
$fa-var-fort-awesome: "\f286";
$fa-var-forumbee: "\f211";
$fa-var-forward: "\f04e";
$fa-var-foursquare: "\f180";
$fa-var-free-code-camp: "\f2c5";
$fa-var-frown-o: "\f119";
$fa-var-futbol-o: "\f1e3";
$fa-var-gamepad: "\f11b";
$fa-var-gavel: "\f0e3";
$fa-var-gbp: "\f154";
$fa-var-ge: "\f1d1";
$fa-var-gear: "\f013";
$fa-var-gears: "\f085";
$fa-var-genderless: "\f22d";
$fa-var-get-pocket: "\f265";
$fa-var-gg: "\f260";
$fa-var-gg-circle: "\f261";
$fa-var-gift: "\f06b";
$fa-var-git: "\f1d3";
$fa-var-git-square: "\f1d2";
$fa-var-github: "\f09b";
$fa-var-github-alt: "\f113";
$fa-var-github-square: "\f092";
$fa-var-gitlab: "\f296";
$fa-var-gittip: "\f184";
$fa-var-glass: "\f000";
$fa-var-glide: "\f2a5";
$fa-var-glide-g: "\f2a6";
$fa-var-globe: "\f0ac";
$fa-var-google: "\f1a0";
$fa-var-google-plus: "\f0d5";
$fa-var-google-plus-circle: "\f2b3";
$fa-var-google-plus-official: "\f2b3";
$fa-var-google-plus-square: "\f0d4";
$fa-var-google-wallet: "\f1ee";
$fa-var-graduation-cap: "\f19d";
$fa-var-gratipay: "\f184";
$fa-var-grav: "\f2d6";
$fa-var-group: "\f0c0";
$fa-var-h-square: "\f0fd";
$fa-var-hacker-news: "\f1d4";
$fa-var-hand-grab-o: "\f255";
$fa-var-hand-lizard-o: "\f258";
$fa-var-hand-o-down: "\f0a7";
$fa-var-hand-o-left: "\f0a5";
$fa-var-hand-o-right: "\f0a4";
$fa-var-hand-o-up: "\f0a6";
$fa-var-hand-paper-o: "\f256";
$fa-var-hand-peace-o: "\f25b";
$fa-var-hand-pointer-o: "\f25a";
$fa-var-hand-rock-o: "\f255";
$fa-var-hand-scissors-o: "\f257";
$fa-var-hand-spock-o: "\f259";
$fa-var-hand-stop-o: "\f256";
$fa-var-handshake-o: "\f2b5";
$fa-var-hard-of-hearing: "\f2a4";
$fa-var-hashtag: "\f292";
$fa-var-hdd-o: "\f0a0";
$fa-var-header: "\f1dc";
$fa-var-headphones: "\f025";
$fa-var-heart: "\f004";
$fa-var-heart-o: "\f08a";
$fa-var-heartbeat: "\f21e";
$fa-var-history: "\f1da";
$fa-var-home: "\f015";
$fa-var-hospital-o: "\f0f8";
$fa-var-hotel: "\f236";
$fa-var-hourglass: "\f254";
$fa-var-hourglass-1: "\f251";
$fa-var-hourglass-2: "\f252";
$fa-var-hourglass-3: "\f253";
$fa-var-hourglass-end: "\f253";
$fa-var-hourglass-half: "\f252";
$fa-var-hourglass-o: "\f250";
$fa-var-hourglass-start: "\f251";
$fa-var-houzz: "\f27c";
$fa-var-html5: "\f13b";
$fa-var-i-cursor: "\f246";
$fa-var-id-badge: "\f2c1";
$fa-var-id-card: "\f2c2";
$fa-var-id-card-o: "\f2c3";
$fa-var-ils: "\f20b";
$fa-var-image: "\f03e";
$fa-var-imdb: "\f2d8";
$fa-var-inbox: "\f01c";
$fa-var-indent: "\f03c";
$fa-var-industry: "\f275";
$fa-var-info: "\f129";
$fa-var-info-circle: "\f05a";
$fa-var-inr: "\f156";
$fa-var-instagram: "\f16d";
$fa-var-institution: "\f19c";
$fa-var-internet-explorer: "\f26b";
$fa-var-intersex: "\f224";
$fa-var-ioxhost: "\f208";
$fa-var-italic: "\f033";
$fa-var-joomla: "\f1aa";
$fa-var-jpy: "\f157";
$fa-var-jsfiddle: "\f1cc";
$fa-var-key: "\f084";
$fa-var-keyboard-o: "\f11c";
$fa-var-krw: "\f159";
$fa-var-language: "\f1ab";
$fa-var-laptop: "\f109";
$fa-var-lastfm: "\f202";
$fa-var-lastfm-square: "\f203";
$fa-var-leaf: "\f06c";
$fa-var-leanpub: "\f212";
$fa-var-legal: "\f0e3";
$fa-var-lemon-o: "\f094";
$fa-var-level-down: "\f149";
$fa-var-level-up: "\f148";
$fa-var-life-bouy: "\f1cd";
$fa-var-life-buoy: "\f1cd";
$fa-var-life-ring: "\f1cd";
$fa-var-life-saver: "\f1cd";
$fa-var-lightbulb-o: "\f0eb";
$fa-var-line-chart: "\f201";
$fa-var-link: "\f0c1";
$fa-var-linkedin: "\f0e1";
$fa-var-linkedin-square: "\f08c";
$fa-var-linode: "\f2b8";
$fa-var-linux: "\f17c";
$fa-var-list: "\f03a";
$fa-var-list-alt: "\f022";
$fa-var-list-ol: "\f0cb";
$fa-var-list-ul: "\f0ca";
$fa-var-location-arrow: "\f124";
$fa-var-lock: "\f023";
$fa-var-long-arrow-down: "\f175";
$fa-var-long-arrow-left: "\f177";
$fa-var-long-arrow-right: "\f178";
$fa-var-long-arrow-up: "\f176";
$fa-var-low-vision: "\f2a8";
$fa-var-magic: "\f0d0";
$fa-var-magnet: "\f076";
$fa-var-mail-forward: "\f064";
$fa-var-mail-reply: "\f112";
$fa-var-mail-reply-all: "\f122";
$fa-var-male: "\f183";
$fa-var-map: "\f279";
$fa-var-map-marker: "\f041";
$fa-var-map-o: "\f278";
$fa-var-map-pin: "\f276";
$fa-var-map-signs: "\f277";
$fa-var-mars: "\f222";
$fa-var-mars-double: "\f227";
$fa-var-mars-stroke: "\f229";
$fa-var-mars-stroke-h: "\f22b";
$fa-var-mars-stroke-v: "\f22a";
$fa-var-maxcdn: "\f136";
$fa-var-meanpath: "\f20c";
$fa-var-medium: "\f23a";
$fa-var-medkit: "\f0fa";
$fa-var-meetup: "\f2e0";
$fa-var-meh-o: "\f11a";
$fa-var-mercury: "\f223";
$fa-var-microchip: "\f2db";
$fa-var-microphone: "\f130";
$fa-var-microphone-slash: "\f131";
$fa-var-minus: "\f068";
$fa-var-minus-circle: "\f056";
$fa-var-minus-square: "\f146";
$fa-var-minus-square-o: "\f147";
$fa-var-mixcloud: "\f289";
$fa-var-mobile: "\f10b";
$fa-var-mobile-phone: "\f10b";
$fa-var-modx: "\f285";
$fa-var-money: "\f0d6";
$fa-var-moon-o: "\f186";
$fa-var-mortar-board: "\f19d";
$fa-var-motorcycle: "\f21c";
$fa-var-mouse-pointer: "\f245";
$fa-var-music: "\f001";
$fa-var-navicon: "\f0c9";
$fa-var-neuter: "\f22c";
$fa-var-newspaper-o: "\f1ea";
$fa-var-object-group: "\f247";
$fa-var-object-ungroup: "\f248";
$fa-var-odnoklassniki: "\f263";
$fa-var-odnoklassniki-square: "\f264";
$fa-var-opencart: "\f23d";
$fa-var-openid: "\f19b";
$fa-var-opera: "\f26a";
$fa-var-optin-monster: "\f23c";
$fa-var-outdent: "\f03b";
$fa-var-pagelines: "\f18c";
$fa-var-paint-brush: "\f1fc";
$fa-var-paper-plane: "\f1d8";
$fa-var-paper-plane-o: "\f1d9";
$fa-var-paperclip: "\f0c6";
$fa-var-paragraph: "\f1dd";
$fa-var-paste: "\f0ea";
$fa-var-pause: "\f04c";
$fa-var-pause-circle: "\f28b";
$fa-var-pause-circle-o: "\f28c";
$fa-var-paw: "\f1b0";
$fa-var-paypal: "\f1ed";
$fa-var-pencil: "\f040";
$fa-var-pencil-square: "\f14b";
$fa-var-pencil-square-o: "\f044";
$fa-var-percent: "\f295";
$fa-var-phone: "\f095";
$fa-var-phone-square: "\f098";
$fa-var-photo: "\f03e";
$fa-var-picture-o: "\f03e";
$fa-var-pie-chart: "\f200";
$fa-var-pied-piper: "\f2ae";
$fa-var-pied-piper-alt: "\f1a8";
$fa-var-pied-piper-pp: "\f1a7";
$fa-var-pinterest: "\f0d2";
$fa-var-pinterest-p: "\f231";
$fa-var-pinterest-square: "\f0d3";
$fa-var-plane: "\f072";
$fa-var-play: "\f04b";
$fa-var-play-circle: "\f144";
$fa-var-play-circle-o: "\f01d";
$fa-var-plug: "\f1e6";
$fa-var-plus: "\f067";
$fa-var-plus-circle: "\f055";
$fa-var-plus-square: "\f0fe";
$fa-var-plus-square-o: "\f196";
$fa-var-podcast: "\f2ce";
$fa-var-power-off: "\f011";
$fa-var-print: "\f02f";
$fa-var-product-hunt: "\f288";
$fa-var-puzzle-piece: "\f12e";
$fa-var-qq: "\f1d6";
$fa-var-qrcode: "\f029";
$fa-var-question: "\f128";
$fa-var-question-circle: "\f059";
$fa-var-question-circle-o: "\f29c";
$fa-var-quora: "\f2c4";
$fa-var-quote-left: "\f10d";
$fa-var-quote-right: "\f10e";
$fa-var-ra: "\f1d0";
$fa-var-random: "\f074";
$fa-var-ravelry: "\f2d9";
$fa-var-rebel: "\f1d0";
$fa-var-recycle: "\f1b8";
$fa-var-reddit: "\f1a1";
$fa-var-reddit-alien: "\f281";
$fa-var-reddit-square: "\f1a2";
$fa-var-refresh: "\f021";
$fa-var-registered: "\f25d";
$fa-var-remove: "\f00d";
$fa-var-renren: "\f18b";
$fa-var-reorder: "\f0c9";
$fa-var-repeat: "\f01e";
$fa-var-reply: "\f112";
$fa-var-reply-all: "\f122";
$fa-var-resistance: "\f1d0";
$fa-var-retweet: "\f079";
$fa-var-rmb: "\f157";
$fa-var-road: "\f018";
$fa-var-rocket: "\f135";
$fa-var-rotate-left: "\f0e2";
$fa-var-rotate-right: "\f01e";
$fa-var-rouble: "\f158";
$fa-var-rss: "\f09e";
$fa-var-rss-square: "\f143";
$fa-var-rub: "\f158";
$fa-var-ruble: "\f158";
$fa-var-rupee: "\f156";
$fa-var-s15: "\f2cd";
$fa-var-safari: "\f267";
$fa-var-save: "\f0c7";
$fa-var-scissors: "\f0c4";
$fa-var-scribd: "\f28a";
$fa-var-search: "\f002";
$fa-var-search-minus: "\f010";
$fa-var-search-plus: "\f00e";
$fa-var-sellsy: "\f213";
$fa-var-send: "\f1d8";
$fa-var-send-o: "\f1d9";
$fa-var-server: "\f233";
$fa-var-share: "\f064";
$fa-var-share-alt: "\f1e0";
$fa-var-share-alt-square: "\f1e1";
$fa-var-share-square: "\f14d";
$fa-var-share-square-o: "\f045";
$fa-var-shekel: "\f20b";
$fa-var-sheqel: "\f20b";
$fa-var-shield: "\f132";
$fa-var-ship: "\f21a";
$fa-var-shirtsinbulk: "\f214";
$fa-var-shopping-bag: "\f290";
$fa-var-shopping-basket: "\f291";
$fa-var-shopping-cart: "\f07a";
$fa-var-shower: "\f2cc";
$fa-var-sign-in: "\f090";
$fa-var-sign-language: "\f2a7";
$fa-var-sign-out: "\f08b";
$fa-var-signal: "\f012";
$fa-var-signing: "\f2a7";
$fa-var-simplybuilt: "\f215";
$fa-var-sitemap: "\f0e8";
$fa-var-skyatlas: "\f216";
$fa-var-skype: "\f17e";
$fa-var-slack: "\f198";
$fa-var-sliders: "\f1de";
$fa-var-slideshare: "\f1e7";
$fa-var-smile-o: "\f118";
$fa-var-snapchat: "\f2ab";
$fa-var-snapchat-ghost: "\f2ac";
$fa-var-snapchat-square: "\f2ad";
$fa-var-snowflake-o: "\f2dc";
$fa-var-soccer-ball-o: "\f1e3";
$fa-var-sort: "\f0dc";
$fa-var-sort-alpha-asc: "\f15d";
$fa-var-sort-alpha-desc: "\f15e";
$fa-var-sort-amount-asc: "\f160";
$fa-var-sort-amount-desc: "\f161";
$fa-var-sort-asc: "\f0de";
$fa-var-sort-desc: "\f0dd";
$fa-var-sort-down: "\f0dd";
$fa-var-sort-numeric-asc: "\f162";
$fa-var-sort-numeric-desc: "\f163";
$fa-var-sort-up: "\f0de";
$fa-var-soundcloud: "\f1be";
$fa-var-space-shuttle: "\f197";
$fa-var-spinner: "\f110";
$fa-var-spoon: "\f1b1";
$fa-var-spotify: "\f1bc";
$fa-var-square: "\f0c8";
$fa-var-square-o: "\f096";
$fa-var-stack-exchange: "\f18d";
$fa-var-stack-overflow: "\f16c";
$fa-var-star: "\f005";
$fa-var-star-half: "\f089";
$fa-var-star-half-empty: "\f123";
$fa-var-star-half-full: "\f123";
$fa-var-star-half-o: "\f123";
$fa-var-star-o: "\f006";
$fa-var-steam: "\f1b6";
$fa-var-steam-square: "\f1b7";
$fa-var-step-backward: "\f048";
$fa-var-step-forward: "\f051";
$fa-var-stethoscope: "\f0f1";
$fa-var-sticky-note: "\f249";
$fa-var-sticky-note-o: "\f24a";
$fa-var-stop: "\f04d";
$fa-var-stop-circle: "\f28d";
$fa-var-stop-circle-o: "\f28e";
$fa-var-street-view: "\f21d";
$fa-var-strikethrough: "\f0cc";
$fa-var-stumbleupon: "\f1a4";
$fa-var-stumbleupon-circle: "\f1a3";
$fa-var-subscript: "\f12c";
$fa-var-subway: "\f239";
$fa-var-suitcase: "\f0f2";
$fa-var-sun-o: "\f185";
$fa-var-superpowers: "\f2dd";
$fa-var-superscript: "\f12b";
$fa-var-support: "\f1cd";
$fa-var-table: "\f0ce";
$fa-var-tablet: "\f10a";
$fa-var-tachometer: "\f0e4";
$fa-var-tag: "\f02b";
$fa-var-tags: "\f02c";
$fa-var-tasks: "\f0ae";
$fa-var-taxi: "\f1ba";
$fa-var-telegram: "\f2c6";
$fa-var-television: "\f26c";
$fa-var-tencent-weibo: "\f1d5";
$fa-var-terminal: "\f120";
$fa-var-text-height: "\f034";
$fa-var-text-width: "\f035";
$fa-var-th: "\f00a";
$fa-var-th-large: "\f009";
$fa-var-th-list: "\f00b";
$fa-var-themeisle: "\f2b2";
$fa-var-thermometer: "\f2c7";
$fa-var-thermometer-0: "\f2cb";
$fa-var-thermometer-1: "\f2ca";
$fa-var-thermometer-2: "\f2c9";
$fa-var-thermometer-3: "\f2c8";
$fa-var-thermometer-4: "\f2c7";
$fa-var-thermometer-empty: "\f2cb";
$fa-var-thermometer-full: "\f2c7";
$fa-var-thermometer-half: "\f2c9";
$fa-var-thermometer-quarter: "\f2ca";
$fa-var-thermometer-three-quarters: "\f2c8";
$fa-var-thumb-tack: "\f08d";
$fa-var-thumbs-down: "\f165";
$fa-var-thumbs-o-down: "\f088";
$fa-var-thumbs-o-up: "\f087";
$fa-var-thumbs-up: "\f164";
$fa-var-ticket: "\f145";
$fa-var-times: "\f00d";
$fa-var-times-circle: "\f057";
$fa-var-times-circle-o: "\f05c";
$fa-var-times-rectangle: "\f2d3";
$fa-var-times-rectangle-o: "\f2d4";
$fa-var-tint: "\f043";
$fa-var-toggle-down: "\f150";
$fa-var-toggle-left: "\f191";
$fa-var-toggle-off: "\f204";
$fa-var-toggle-on: "\f205";
$fa-var-toggle-right: "\f152";
$fa-var-toggle-up: "\f151";
$fa-var-trademark: "\f25c";
$fa-var-train: "\f238";
$fa-var-transgender: "\f224";
$fa-var-transgender-alt: "\f225";
$fa-var-trash: "\f1f8";
$fa-var-trash-o: "\f014";
$fa-var-tree: "\f1bb";
$fa-var-trello: "\f181";
$fa-var-tripadvisor: "\f262";
$fa-var-trophy: "\f091";
$fa-var-truck: "\f0d1";
$fa-var-try: "\f195";
$fa-var-tty: "\f1e4";
$fa-var-tumblr: "\f173";
$fa-var-tumblr-square: "\f174";
$fa-var-turkish-lira: "\f195";
$fa-var-tv: "\f26c";
$fa-var-twitch: "\f1e8";
$fa-var-twitter: "\f099";
$fa-var-twitter-square: "\f081";
$fa-var-umbrella: "\f0e9";
$fa-var-underline: "\f0cd";
$fa-var-undo: "\f0e2";
$fa-var-universal-access: "\f29a";
$fa-var-university: "\f19c";
$fa-var-unlink: "\f127";
$fa-var-unlock: "\f09c";
$fa-var-unlock-alt: "\f13e";
$fa-var-unsorted: "\f0dc";
$fa-var-upload: "\f093";
$fa-var-usb: "\f287";
$fa-var-usd: "\f155";
$fa-var-user: "\f007";
$fa-var-user-circle: "\f2bd";
$fa-var-user-circle-o: "\f2be";
$fa-var-user-md: "\f0f0";
$fa-var-user-o: "\f2c0";
$fa-var-user-plus: "\f234";
$fa-var-user-secret: "\f21b";
$fa-var-user-times: "\f235";
$fa-var-users: "\f0c0";
$fa-var-vcard: "\f2bb";
$fa-var-vcard-o: "\f2bc";
$fa-var-venus: "\f221";
$fa-var-venus-double: "\f226";
$fa-var-venus-mars: "\f228";
$fa-var-viacoin: "\f237";
$fa-var-viadeo: "\f2a9";
$fa-var-viadeo-square: "\f2aa";
$fa-var-video-camera: "\f03d";
$fa-var-vimeo: "\f27d";
$fa-var-vimeo-square: "\f194";
$fa-var-vine: "\f1ca";
$fa-var-vk: "\f189";
$fa-var-volume-control-phone: "\f2a0";
$fa-var-volume-down: "\f027";
$fa-var-volume-off: "\f026";
$fa-var-volume-up: "\f028";
$fa-var-warning: "\f071";
$fa-var-wechat: "\f1d7";
$fa-var-weibo: "\f18a";
$fa-var-weixin: "\f1d7";
$fa-var-whatsapp: "\f232";
$fa-var-wheelchair: "\f193";
$fa-var-wheelchair-alt: "\f29b";
$fa-var-wifi: "\f1eb";
$fa-var-wikipedia-w: "\f266";
$fa-var-window-close: "\f2d3";
$fa-var-window-close-o: "\f2d4";
$fa-var-window-maximize: "\f2d0";
$fa-var-window-minimize: "\f2d1";
$fa-var-window-restore: "\f2d2";
$fa-var-windows: "\f17a";
$fa-var-won: "\f159";
$fa-var-wordpress: "\f19a";
$fa-var-wpbeginner: "\f297";
$fa-var-wpexplorer: "\f2de";
$fa-var-wpforms: "\f298";
$fa-var-wrench: "\f0ad";
$fa-var-xing: "\f168";
$fa-var-xing-square: "\f169";
$fa-var-y-combinator: "\f23b";
$fa-var-y-combinator-square: "\f1d4";
$fa-var-yahoo: "\f19e";
$fa-var-yc: "\f23b";
$fa-var-yc-square: "\f1d4";
$fa-var-yelp: "\f1e9";
$fa-var-yen: "\f157";
$fa-var-yoast: "\f2b1";
$fa-var-youtube: "\f167";
$fa-var-youtube-play: "\f16a";
$fa-var-youtube-square: "\f166";

@mixin fa-icon() {
  display: inline-block;
  font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@mixin fa-icon-rotate($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}


// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}


@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('../fonts/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('../fonts/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('../fonts/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('../fonts/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
//  src: url('../fonts/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

.#{$fa-css-prefix} {
  display: inline-block;
  font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}


/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-glass:before { content: $fa-var-glass; }
.#{$fa-css-prefix}-music:before { content: $fa-var-music; }
.#{$fa-css-prefix}-search:before { content: $fa-var-search; }
.#{$fa-css-prefix}-envelope-o:before { content: $fa-var-envelope-o; }
.#{$fa-css-prefix}-heart:before { content: $fa-var-heart; }
.#{$fa-css-prefix}-star:before { content: $fa-var-star; }
.#{$fa-css-prefix}-star-o:before { content: $fa-var-star-o; }
.#{$fa-css-prefix}-user:before { content: $fa-var-user; }
.#{$fa-css-prefix}-film:before { content: $fa-var-film; }
.#{$fa-css-prefix}-th-large:before { content: $fa-var-th-large; }
.#{$fa-css-prefix}-th:before { content: $fa-var-th; }
.#{$fa-css-prefix}-th-list:before { content: $fa-var-th-list; }
.#{$fa-css-prefix}-check:before { content: $fa-var-check; }
.#{$fa-css-prefix}-remove:before,
.#{$fa-css-prefix}-close:before,
.#{$fa-css-prefix}-times:before { content: $fa-var-times; }
.#{$fa-css-prefix}-search-plus:before { content: $fa-var-search-plus; }
.#{$fa-css-prefix}-search-minus:before { content: $fa-var-search-minus; }
.#{$fa-css-prefix}-power-off:before { content: $fa-var-power-off; }
.#{$fa-css-prefix}-signal:before { content: $fa-var-signal; }
.#{$fa-css-prefix}-gear:before,
.#{$fa-css-prefix}-cog:before { content: $fa-var-cog; }
.#{$fa-css-prefix}-trash-o:before { content: $fa-var-trash-o; }
.#{$fa-css-prefix}-home:before { content: $fa-var-home; }
.#{$fa-css-prefix}-file-o:before { content: $fa-var-file-o; }
.#{$fa-css-prefix}-clock-o:before { content: $fa-var-clock-o; }
.#{$fa-css-prefix}-road:before { content: $fa-var-road; }
.#{$fa-css-prefix}-download:before { content: $fa-var-download; }
.#{$fa-css-prefix}-arrow-circle-o-down:before { content: $fa-var-arrow-circle-o-down; }
.#{$fa-css-prefix}-arrow-circle-o-up:before { content: $fa-var-arrow-circle-o-up; }
.#{$fa-css-prefix}-inbox:before { content: $fa-var-inbox; }
.#{$fa-css-prefix}-play-circle-o:before { content: $fa-var-play-circle-o; }
.#{$fa-css-prefix}-rotate-right:before,
.#{$fa-css-prefix}-repeat:before { content: $fa-var-repeat; }
.#{$fa-css-prefix}-refresh:before { content: $fa-var-refresh; }
.#{$fa-css-prefix}-list-alt:before { content: $fa-var-list-alt; }
.#{$fa-css-prefix}-lock:before { content: $fa-var-lock; }
.#{$fa-css-prefix}-flag:before { content: $fa-var-flag; }
.#{$fa-css-prefix}-headphones:before { content: $fa-var-headphones; }
.#{$fa-css-prefix}-volume-off:before { content: $fa-var-volume-off; }
.#{$fa-css-prefix}-volume-down:before { content: $fa-var-volume-down; }
.#{$fa-css-prefix}-volume-up:before { content: $fa-var-volume-up; }
.#{$fa-css-prefix}-qrcode:before { content: $fa-var-qrcode; }
.#{$fa-css-prefix}-barcode:before { content: $fa-var-barcode; }
.#{$fa-css-prefix}-tag:before { content: $fa-var-tag; }
.#{$fa-css-prefix}-tags:before { content: $fa-var-tags; }
.#{$fa-css-prefix}-book:before { content: $fa-var-book; }
.#{$fa-css-prefix}-bookmark:before { content: $fa-var-bookmark; }
.#{$fa-css-prefix}-print:before { content: $fa-var-print; }
.#{$fa-css-prefix}-camera:before { content: $fa-var-camera; }
.#{$fa-css-prefix}-font:before { content: $fa-var-font; }
.#{$fa-css-prefix}-bold:before { content: $fa-var-bold; }
.#{$fa-css-prefix}-italic:before { content: $fa-var-italic; }
.#{$fa-css-prefix}-text-height:before { content: $fa-var-text-height; }
.#{$fa-css-prefix}-text-width:before { content: $fa-var-text-width; }
.#{$fa-css-prefix}-align-left:before { content: $fa-var-align-left; }
.#{$fa-css-prefix}-align-center:before { content: $fa-var-align-center; }
.#{$fa-css-prefix}-align-right:before { content: $fa-var-align-right; }
.#{$fa-css-prefix}-align-justify:before { content: $fa-var-align-justify; }
.#{$fa-css-prefix}-list:before { content: $fa-var-list; }
.#{$fa-css-prefix}-dedent:before,
.#{$fa-css-prefix}-outdent:before { content: $fa-var-outdent; }
.#{$fa-css-prefix}-indent:before { content: $fa-var-indent; }
.#{$fa-css-prefix}-video-camera:before { content: $fa-var-video-camera; }
.#{$fa-css-prefix}-photo:before,
.#{$fa-css-prefix}-image:before,
.#{$fa-css-prefix}-picture-o:before { content: $fa-var-picture-o; }
.#{$fa-css-prefix}-pencil:before { content: $fa-var-pencil; }
.#{$fa-css-prefix}-map-marker:before { content: $fa-var-map-marker; }
.#{$fa-css-prefix}-adjust:before { content: $fa-var-adjust; }
.#{$fa-css-prefix}-tint:before { content: $fa-var-tint; }
.#{$fa-css-prefix}-edit:before,
.#{$fa-css-prefix}-pencil-square-o:before { content: $fa-var-pencil-square-o; }
.#{$fa-css-prefix}-share-square-o:before { content: $fa-var-share-square-o; }
.#{$fa-css-prefix}-check-square-o:before { content: $fa-var-check-square-o; }
.#{$fa-css-prefix}-arrows:before { content: $fa-var-arrows; }
.#{$fa-css-prefix}-step-backward:before { content: $fa-var-step-backward; }
.#{$fa-css-prefix}-fast-backward:before { content: $fa-var-fast-backward; }
.#{$fa-css-prefix}-backward:before { content: $fa-var-backward; }
.#{$fa-css-prefix}-play:before { content: $fa-var-play; }
.#{$fa-css-prefix}-pause:before { content: $fa-var-pause; }
.#{$fa-css-prefix}-stop:before { content: $fa-var-stop; }
.#{$fa-css-prefix}-forward:before { content: $fa-var-forward; }
.#{$fa-css-prefix}-fast-forward:before { content: $fa-var-fast-forward; }
.#{$fa-css-prefix}-step-forward:before { content: $fa-var-step-forward; }
.#{$fa-css-prefix}-eject:before { content: $fa-var-eject; }
.#{$fa-css-prefix}-chevron-left:before { content: $fa-var-chevron-left; }
.#{$fa-css-prefix}-chevron-right:before { content: $fa-var-chevron-right; }
.#{$fa-css-prefix}-plus-circle:before { content: $fa-var-plus-circle; }
.#{$fa-css-prefix}-minus-circle:before { content: $fa-var-minus-circle; }
.#{$fa-css-prefix}-times-circle:before { content: $fa-var-times-circle; }
.#{$fa-css-prefix}-check-circle:before { content: $fa-var-check-circle; }
.#{$fa-css-prefix}-question-circle:before { content: $fa-var-question-circle; }
.#{$fa-css-prefix}-info-circle:before { content: $fa-var-info-circle; }
.#{$fa-css-prefix}-crosshairs:before { content: $fa-var-crosshairs; }
.#{$fa-css-prefix}-times-circle-o:before { content: $fa-var-times-circle-o; }
.#{$fa-css-prefix}-check-circle-o:before { content: $fa-var-check-circle-o; }
.#{$fa-css-prefix}-ban:before { content: $fa-var-ban; }
.#{$fa-css-prefix}-arrow-left:before { content: $fa-var-arrow-left; }
.#{$fa-css-prefix}-arrow-right:before { content: $fa-var-arrow-right; }
.#{$fa-css-prefix}-arrow-up:before { content: $fa-var-arrow-up; }
.#{$fa-css-prefix}-arrow-down:before { content: $fa-var-arrow-down; }
.#{$fa-css-prefix}-mail-forward:before,
.#{$fa-css-prefix}-share:before { content: $fa-var-share; }
.#{$fa-css-prefix}-expand:before { content: $fa-var-expand; }
.#{$fa-css-prefix}-compress:before { content: $fa-var-compress; }
.#{$fa-css-prefix}-plus:before { content: $fa-var-plus; }
.#{$fa-css-prefix}-minus:before { content: $fa-var-minus; }
.#{$fa-css-prefix}-asterisk:before { content: $fa-var-asterisk; }
.#{$fa-css-prefix}-exclamation-circle:before { content: $fa-var-exclamation-circle; }
.#{$fa-css-prefix}-gift:before { content: $fa-var-gift; }
.#{$fa-css-prefix}-leaf:before { content: $fa-var-leaf; }
.#{$fa-css-prefix}-fire:before { content: $fa-var-fire; }
.#{$fa-css-prefix}-eye:before { content: $fa-var-eye; }
.#{$fa-css-prefix}-eye-slash:before { content: $fa-var-eye-slash; }
.#{$fa-css-prefix}-warning:before,
.#{$fa-css-prefix}-exclamation-triangle:before { content: $fa-var-exclamation-triangle; }
.#{$fa-css-prefix}-plane:before { content: $fa-var-plane; }
.#{$fa-css-prefix}-calendar:before { content: $fa-var-calendar; }
.#{$fa-css-prefix}-random:before { content: $fa-var-random; }
.#{$fa-css-prefix}-comment:before { content: $fa-var-comment; }
.#{$fa-css-prefix}-magnet:before { content: $fa-var-magnet; }
.#{$fa-css-prefix}-chevron-up:before { content: $fa-var-chevron-up; }
.#{$fa-css-prefix}-chevron-down:before { content: $fa-var-chevron-down; }
.#{$fa-css-prefix}-retweet:before { content: $fa-var-retweet; }
.#{$fa-css-prefix}-shopping-cart:before { content: $fa-var-shopping-cart; }
.#{$fa-css-prefix}-folder:before { content: $fa-var-folder; }
.#{$fa-css-prefix}-folder-open:before { content: $fa-var-folder-open; }
.#{$fa-css-prefix}-arrows-v:before { content: $fa-var-arrows-v; }
.#{$fa-css-prefix}-arrows-h:before { content: $fa-var-arrows-h; }
.#{$fa-css-prefix}-bar-chart-o:before,
.#{$fa-css-prefix}-bar-chart:before { content: $fa-var-bar-chart; }
.#{$fa-css-prefix}-twitter-square:before { content: $fa-var-twitter-square; }
.#{$fa-css-prefix}-facebook-square:before { content: $fa-var-facebook-square; }
.#{$fa-css-prefix}-camera-retro:before { content: $fa-var-camera-retro; }
.#{$fa-css-prefix}-key:before { content: $fa-var-key; }
.#{$fa-css-prefix}-gears:before,
.#{$fa-css-prefix}-cogs:before { content: $fa-var-cogs; }
.#{$fa-css-prefix}-comments:before { content: $fa-var-comments; }
.#{$fa-css-prefix}-thumbs-o-up:before { content: $fa-var-thumbs-o-up; }
.#{$fa-css-prefix}-thumbs-o-down:before { content: $fa-var-thumbs-o-down; }
.#{$fa-css-prefix}-star-half:before { content: $fa-var-star-half; }
.#{$fa-css-prefix}-heart-o:before { content: $fa-var-heart-o; }
.#{$fa-css-prefix}-sign-out:before { content: $fa-var-sign-out; }
.#{$fa-css-prefix}-linkedin-square:before { content: $fa-var-linkedin-square; }
.#{$fa-css-prefix}-thumb-tack:before { content: $fa-var-thumb-tack; }
.#{$fa-css-prefix}-external-link:before { content: $fa-var-external-link; }
.#{$fa-css-prefix}-sign-in:before { content: $fa-var-sign-in; }
.#{$fa-css-prefix}-trophy:before { content: $fa-var-trophy; }
.#{$fa-css-prefix}-github-square:before { content: $fa-var-github-square; }
.#{$fa-css-prefix}-upload:before { content: $fa-var-upload; }
.#{$fa-css-prefix}-lemon-o:before { content: $fa-var-lemon-o; }
.#{$fa-css-prefix}-phone:before { content: $fa-var-phone; }
.#{$fa-css-prefix}-square-o:before { content: $fa-var-square-o; }
.#{$fa-css-prefix}-bookmark-o:before { content: $fa-var-bookmark-o; }
.#{$fa-css-prefix}-phone-square:before { content: $fa-var-phone-square; }
.#{$fa-css-prefix}-twitter:before { content: $fa-var-twitter; }
.#{$fa-css-prefix}-facebook-f:before,
.#{$fa-css-prefix}-facebook:before { content: $fa-var-facebook; }
.#{$fa-css-prefix}-github:before { content: $fa-var-github; }
.#{$fa-css-prefix}-unlock:before { content: $fa-var-unlock; }
.#{$fa-css-prefix}-credit-card:before { content: $fa-var-credit-card; }
.#{$fa-css-prefix}-feed:before,
.#{$fa-css-prefix}-rss:before { content: $fa-var-rss; }
.#{$fa-css-prefix}-hdd-o:before { content: $fa-var-hdd-o; }
.#{$fa-css-prefix}-bullhorn:before { content: $fa-var-bullhorn; }
.#{$fa-css-prefix}-bell:before { content: $fa-var-bell; }
.#{$fa-css-prefix}-certificate:before { content: $fa-var-certificate; }
.#{$fa-css-prefix}-hand-o-right:before { content: $fa-var-hand-o-right; }
.#{$fa-css-prefix}-hand-o-left:before { content: $fa-var-hand-o-left; }
.#{$fa-css-prefix}-hand-o-up:before { content: $fa-var-hand-o-up; }
.#{$fa-css-prefix}-hand-o-down:before { content: $fa-var-hand-o-down; }
.#{$fa-css-prefix}-arrow-circle-left:before { content: $fa-var-arrow-circle-left; }
.#{$fa-css-prefix}-arrow-circle-right:before { content: $fa-var-arrow-circle-right; }
.#{$fa-css-prefix}-arrow-circle-up:before { content: $fa-var-arrow-circle-up; }
.#{$fa-css-prefix}-arrow-circle-down:before { content: $fa-var-arrow-circle-down; }
.#{$fa-css-prefix}-globe:before { content: $fa-var-globe; }
.#{$fa-css-prefix}-wrench:before { content: $fa-var-wrench; }
.#{$fa-css-prefix}-tasks:before { content: $fa-var-tasks; }
.#{$fa-css-prefix}-filter:before { content: $fa-var-filter; }
.#{$fa-css-prefix}-briefcase:before { content: $fa-var-briefcase; }
.#{$fa-css-prefix}-arrows-alt:before { content: $fa-var-arrows-alt; }
.#{$fa-css-prefix}-group:before,
.#{$fa-css-prefix}-users:before { content: $fa-var-users; }
.#{$fa-css-prefix}-chain:before,
.#{$fa-css-prefix}-link:before { content: $fa-var-link; }
.#{$fa-css-prefix}-cloud:before { content: $fa-var-cloud; }
.#{$fa-css-prefix}-flask:before { content: $fa-var-flask; }
.#{$fa-css-prefix}-cut:before,
.#{$fa-css-prefix}-scissors:before { content: $fa-var-scissors; }
.#{$fa-css-prefix}-copy:before,
.#{$fa-css-prefix}-files-o:before { content: $fa-var-files-o; }
.#{$fa-css-prefix}-paperclip:before { content: $fa-var-paperclip; }
.#{$fa-css-prefix}-save:before,
.#{$fa-css-prefix}-floppy-o:before { content: $fa-var-floppy-o; }
.#{$fa-css-prefix}-square:before { content: $fa-var-square; }
.#{$fa-css-prefix}-navicon:before,
.#{$fa-css-prefix}-reorder:before,
.#{$fa-css-prefix}-bars:before { content: $fa-var-bars; }
.#{$fa-css-prefix}-list-ul:before { content: $fa-var-list-ul; }
.#{$fa-css-prefix}-list-ol:before { content: $fa-var-list-ol; }
.#{$fa-css-prefix}-strikethrough:before { content: $fa-var-strikethrough; }
.#{$fa-css-prefix}-underline:before { content: $fa-var-underline; }
.#{$fa-css-prefix}-table:before { content: $fa-var-table; }
.#{$fa-css-prefix}-magic:before { content: $fa-var-magic; }
.#{$fa-css-prefix}-truck:before { content: $fa-var-truck; }
.#{$fa-css-prefix}-pinterest:before { content: $fa-var-pinterest; }
.#{$fa-css-prefix}-pinterest-square:before { content: $fa-var-pinterest-square; }
.#{$fa-css-prefix}-google-plus-square:before { content: $fa-var-google-plus-square; }
.#{$fa-css-prefix}-google-plus:before { content: $fa-var-google-plus; }
.#{$fa-css-prefix}-money:before { content: $fa-var-money; }
.#{$fa-css-prefix}-caret-down:before { content: $fa-var-caret-down; }
.#{$fa-css-prefix}-caret-up:before { content: $fa-var-caret-up; }
.#{$fa-css-prefix}-caret-left:before { content: $fa-var-caret-left; }
.#{$fa-css-prefix}-caret-right:before { content: $fa-var-caret-right; }
.#{$fa-css-prefix}-columns:before { content: $fa-var-columns; }
.#{$fa-css-prefix}-unsorted:before,
.#{$fa-css-prefix}-sort:before { content: $fa-var-sort; }
.#{$fa-css-prefix}-sort-down:before,
.#{$fa-css-prefix}-sort-desc:before { content: $fa-var-sort-desc; }
.#{$fa-css-prefix}-sort-up:before,
.#{$fa-css-prefix}-sort-asc:before { content: $fa-var-sort-asc; }
.#{$fa-css-prefix}-envelope:before { content: $fa-var-envelope; }
.#{$fa-css-prefix}-linkedin:before { content: $fa-var-linkedin; }
.#{$fa-css-prefix}-rotate-left:before,
.#{$fa-css-prefix}-undo:before { content: $fa-var-undo; }
.#{$fa-css-prefix}-legal:before,
.#{$fa-css-prefix}-gavel:before { content: $fa-var-gavel; }
.#{$fa-css-prefix}-dashboard:before,
.#{$fa-css-prefix}-tachometer:before { content: $fa-var-tachometer; }
.#{$fa-css-prefix}-comment-o:before { content: $fa-var-comment-o; }
.#{$fa-css-prefix}-comments-o:before { content: $fa-var-comments-o; }
.#{$fa-css-prefix}-flash:before,
.#{$fa-css-prefix}-bolt:before { content: $fa-var-bolt; }
.#{$fa-css-prefix}-sitemap:before { content: $fa-var-sitemap; }
.#{$fa-css-prefix}-umbrella:before { content: $fa-var-umbrella; }
.#{$fa-css-prefix}-paste:before,
.#{$fa-css-prefix}-clipboard:before { content: $fa-var-clipboard; }
.#{$fa-css-prefix}-lightbulb-o:before { content: $fa-var-lightbulb-o; }
.#{$fa-css-prefix}-exchange:before { content: $fa-var-exchange; }
.#{$fa-css-prefix}-cloud-download:before { content: $fa-var-cloud-download; }
.#{$fa-css-prefix}-cloud-upload:before { content: $fa-var-cloud-upload; }
.#{$fa-css-prefix}-user-md:before { content: $fa-var-user-md; }
.#{$fa-css-prefix}-stethoscope:before { content: $fa-var-stethoscope; }
.#{$fa-css-prefix}-suitcase:before { content: $fa-var-suitcase; }
.#{$fa-css-prefix}-bell-o:before { content: $fa-var-bell-o; }
.#{$fa-css-prefix}-coffee:before { content: $fa-var-coffee; }
.#{$fa-css-prefix}-cutlery:before { content: $fa-var-cutlery; }
.#{$fa-css-prefix}-file-text-o:before { content: $fa-var-file-text-o; }
.#{$fa-css-prefix}-building-o:before { content: $fa-var-building-o; }
.#{$fa-css-prefix}-hospital-o:before { content: $fa-var-hospital-o; }
.#{$fa-css-prefix}-ambulance:before { content: $fa-var-ambulance; }
.#{$fa-css-prefix}-medkit:before { content: $fa-var-medkit; }
.#{$fa-css-prefix}-fighter-jet:before { content: $fa-var-fighter-jet; }
.#{$fa-css-prefix}-beer:before { content: $fa-var-beer; }
.#{$fa-css-prefix}-h-square:before { content: $fa-var-h-square; }
.#{$fa-css-prefix}-plus-square:before { content: $fa-var-plus-square; }
.#{$fa-css-prefix}-angle-double-left:before { content: $fa-var-angle-double-left; }
.#{$fa-css-prefix}-angle-double-right:before { content: $fa-var-angle-double-right; }
.#{$fa-css-prefix}-angle-double-up:before { content: $fa-var-angle-double-up; }
.#{$fa-css-prefix}-angle-double-down:before { content: $fa-var-angle-double-down; }
.#{$fa-css-prefix}-angle-left:before { content: $fa-var-angle-left; }
.#{$fa-css-prefix}-angle-right:before { content: $fa-var-angle-right; }
.#{$fa-css-prefix}-angle-up:before { content: $fa-var-angle-up; }
.#{$fa-css-prefix}-angle-down:before { content: $fa-var-angle-down; }
.#{$fa-css-prefix}-desktop:before { content: $fa-var-desktop; }
.#{$fa-css-prefix}-laptop:before { content: $fa-var-laptop; }
.#{$fa-css-prefix}-tablet:before { content: $fa-var-tablet; }
.#{$fa-css-prefix}-mobile-phone:before,
.#{$fa-css-prefix}-mobile:before { content: $fa-var-mobile; }
.#{$fa-css-prefix}-circle-o:before { content: $fa-var-circle-o; }
.#{$fa-css-prefix}-quote-left:before { content: $fa-var-quote-left; }
.#{$fa-css-prefix}-quote-right:before { content: $fa-var-quote-right; }
.#{$fa-css-prefix}-spinner:before { content: $fa-var-spinner; }
.#{$fa-css-prefix}-circle:before { content: $fa-var-circle; }
.#{$fa-css-prefix}-mail-reply:before,
.#{$fa-css-prefix}-reply:before { content: $fa-var-reply; }
.#{$fa-css-prefix}-github-alt:before { content: $fa-var-github-alt; }
.#{$fa-css-prefix}-folder-o:before { content: $fa-var-folder-o; }
.#{$fa-css-prefix}-folder-open-o:before { content: $fa-var-folder-open-o; }
.#{$fa-css-prefix}-smile-o:before { content: $fa-var-smile-o; }
.#{$fa-css-prefix}-frown-o:before { content: $fa-var-frown-o; }
.#{$fa-css-prefix}-meh-o:before { content: $fa-var-meh-o; }
.#{$fa-css-prefix}-gamepad:before { content: $fa-var-gamepad; }
.#{$fa-css-prefix}-keyboard-o:before { content: $fa-var-keyboard-o; }
.#{$fa-css-prefix}-flag-o:before { content: $fa-var-flag-o; }
.#{$fa-css-prefix}-flag-checkered:before { content: $fa-var-flag-checkered; }
.#{$fa-css-prefix}-terminal:before { content: $fa-var-terminal; }
.#{$fa-css-prefix}-code:before { content: $fa-var-code; }
.#{$fa-css-prefix}-mail-reply-all:before,
.#{$fa-css-prefix}-reply-all:before { content: $fa-var-reply-all; }
.#{$fa-css-prefix}-star-half-empty:before,
.#{$fa-css-prefix}-star-half-full:before,
.#{$fa-css-prefix}-star-half-o:before { content: $fa-var-star-half-o; }
.#{$fa-css-prefix}-location-arrow:before { content: $fa-var-location-arrow; }
.#{$fa-css-prefix}-crop:before { content: $fa-var-crop; }
.#{$fa-css-prefix}-code-fork:before { content: $fa-var-code-fork; }
.#{$fa-css-prefix}-unlink:before,
.#{$fa-css-prefix}-chain-broken:before { content: $fa-var-chain-broken; }
.#{$fa-css-prefix}-question:before { content: $fa-var-question; }
.#{$fa-css-prefix}-info:before { content: $fa-var-info; }
.#{$fa-css-prefix}-exclamation:before { content: $fa-var-exclamation; }
.#{$fa-css-prefix}-superscript:before { content: $fa-var-superscript; }
.#{$fa-css-prefix}-subscript:before { content: $fa-var-subscript; }
.#{$fa-css-prefix}-eraser:before { content: $fa-var-eraser; }
.#{$fa-css-prefix}-puzzle-piece:before { content: $fa-var-puzzle-piece; }
.#{$fa-css-prefix}-microphone:before { content: $fa-var-microphone; }
.#{$fa-css-prefix}-microphone-slash:before { content: $fa-var-microphone-slash; }
.#{$fa-css-prefix}-shield:before { content: $fa-var-shield; }
.#{$fa-css-prefix}-calendar-o:before { content: $fa-var-calendar-o; }
.#{$fa-css-prefix}-fire-extinguisher:before { content: $fa-var-fire-extinguisher; }
.#{$fa-css-prefix}-rocket:before { content: $fa-var-rocket; }
.#{$fa-css-prefix}-maxcdn:before { content: $fa-var-maxcdn; }
.#{$fa-css-prefix}-chevron-circle-left:before { content: $fa-var-chevron-circle-left; }
.#{$fa-css-prefix}-chevron-circle-right:before { content: $fa-var-chevron-circle-right; }
.#{$fa-css-prefix}-chevron-circle-up:before { content: $fa-var-chevron-circle-up; }
.#{$fa-css-prefix}-chevron-circle-down:before { content: $fa-var-chevron-circle-down; }
.#{$fa-css-prefix}-html5:before { content: $fa-var-html5; }
.#{$fa-css-prefix}-css3:before { content: $fa-var-css3; }
.#{$fa-css-prefix}-anchor:before { content: $fa-var-anchor; }
.#{$fa-css-prefix}-unlock-alt:before { content: $fa-var-unlock-alt; }
.#{$fa-css-prefix}-bullseye:before { content: $fa-var-bullseye; }
.#{$fa-css-prefix}-ellipsis-h:before { content: $fa-var-ellipsis-h; }
.#{$fa-css-prefix}-ellipsis-v:before { content: $fa-var-ellipsis-v; }
.#{$fa-css-prefix}-rss-square:before { content: $fa-var-rss-square; }
.#{$fa-css-prefix}-play-circle:before { content: $fa-var-play-circle; }
.#{$fa-css-prefix}-ticket:before { content: $fa-var-ticket; }
.#{$fa-css-prefix}-minus-square:before { content: $fa-var-minus-square; }
.#{$fa-css-prefix}-minus-square-o:before { content: $fa-var-minus-square-o; }
.#{$fa-css-prefix}-level-up:before { content: $fa-var-level-up; }
.#{$fa-css-prefix}-level-down:before { content: $fa-var-level-down; }
.#{$fa-css-prefix}-check-square:before { content: $fa-var-check-square; }
.#{$fa-css-prefix}-pencil-square:before { content: $fa-var-pencil-square; }
.#{$fa-css-prefix}-external-link-square:before { content: $fa-var-external-link-square; }
.#{$fa-css-prefix}-share-square:before { content: $fa-var-share-square; }
.#{$fa-css-prefix}-compass:before { content: $fa-var-compass; }
.#{$fa-css-prefix}-toggle-down:before,
.#{$fa-css-prefix}-caret-square-o-down:before { content: $fa-var-caret-square-o-down; }
.#{$fa-css-prefix}-toggle-up:before,
.#{$fa-css-prefix}-caret-square-o-up:before { content: $fa-var-caret-square-o-up; }
.#{$fa-css-prefix}-toggle-right:before,
.#{$fa-css-prefix}-caret-square-o-right:before { content: $fa-var-caret-square-o-right; }
.#{$fa-css-prefix}-euro:before,
.#{$fa-css-prefix}-eur:before { content: $fa-var-eur; }
.#{$fa-css-prefix}-gbp:before { content: $fa-var-gbp; }
.#{$fa-css-prefix}-dollar:before,
.#{$fa-css-prefix}-usd:before { content: $fa-var-usd; }
.#{$fa-css-prefix}-rupee:before,
.#{$fa-css-prefix}-inr:before { content: $fa-var-inr; }
.#{$fa-css-prefix}-cny:before,
.#{$fa-css-prefix}-rmb:before,
.#{$fa-css-prefix}-yen:before,
.#{$fa-css-prefix}-jpy:before { content: $fa-var-jpy; }
.#{$fa-css-prefix}-ruble:before,
.#{$fa-css-prefix}-rouble:before,
.#{$fa-css-prefix}-rub:before { content: $fa-var-rub; }
.#{$fa-css-prefix}-won:before,
.#{$fa-css-prefix}-krw:before { content: $fa-var-krw; }
.#{$fa-css-prefix}-bitcoin:before,
.#{$fa-css-prefix}-btc:before { content: $fa-var-btc; }
.#{$fa-css-prefix}-file:before { content: $fa-var-file; }
.#{$fa-css-prefix}-file-text:before { content: $fa-var-file-text; }
.#{$fa-css-prefix}-sort-alpha-asc:before { content: $fa-var-sort-alpha-asc; }
.#{$fa-css-prefix}-sort-alpha-desc:before { content: $fa-var-sort-alpha-desc; }
.#{$fa-css-prefix}-sort-amount-asc:before { content: $fa-var-sort-amount-asc; }
.#{$fa-css-prefix}-sort-amount-desc:before { content: $fa-var-sort-amount-desc; }
.#{$fa-css-prefix}-sort-numeric-asc:before { content: $fa-var-sort-numeric-asc; }
.#{$fa-css-prefix}-sort-numeric-desc:before { content: $fa-var-sort-numeric-desc; }
.#{$fa-css-prefix}-thumbs-up:before { content: $fa-var-thumbs-up; }
.#{$fa-css-prefix}-thumbs-down:before { content: $fa-var-thumbs-down; }
.#{$fa-css-prefix}-youtube-square:before { content: $fa-var-youtube-square; }
.#{$fa-css-prefix}-youtube:before { content: $fa-var-youtube; }
.#{$fa-css-prefix}-xing:before { content: $fa-var-xing; }
.#{$fa-css-prefix}-xing-square:before { content: $fa-var-xing-square; }
.#{$fa-css-prefix}-youtube-play:before { content: $fa-var-youtube-play; }
.#{$fa-css-prefix}-dropbox:before { content: $fa-var-dropbox; }
.#{$fa-css-prefix}-stack-overflow:before { content: $fa-var-stack-overflow; }
.#{$fa-css-prefix}-instagram:before { content: $fa-var-instagram; }
.#{$fa-css-prefix}-flickr:before { content: $fa-var-flickr; }
.#{$fa-css-prefix}-adn:before { content: $fa-var-adn; }
.#{$fa-css-prefix}-bitbucket:before { content: $fa-var-bitbucket; }
.#{$fa-css-prefix}-bitbucket-square:before { content: $fa-var-bitbucket-square; }
.#{$fa-css-prefix}-tumblr:before { content: $fa-var-tumblr; }
.#{$fa-css-prefix}-tumblr-square:before { content: $fa-var-tumblr-square; }
.#{$fa-css-prefix}-long-arrow-down:before { content: $fa-var-long-arrow-down; }
.#{$fa-css-prefix}-long-arrow-up:before { content: $fa-var-long-arrow-up; }
.#{$fa-css-prefix}-long-arrow-left:before { content: $fa-var-long-arrow-left; }
.#{$fa-css-prefix}-long-arrow-right:before { content: $fa-var-long-arrow-right; }
.#{$fa-css-prefix}-apple:before { content: $fa-var-apple; }
.#{$fa-css-prefix}-windows:before { content: $fa-var-windows; }
.#{$fa-css-prefix}-android:before { content: $fa-var-android; }
.#{$fa-css-prefix}-linux:before { content: $fa-var-linux; }
.#{$fa-css-prefix}-dribbble:before { content: $fa-var-dribbble; }
.#{$fa-css-prefix}-skype:before { content: $fa-var-skype; }
.#{$fa-css-prefix}-foursquare:before { content: $fa-var-foursquare; }
.#{$fa-css-prefix}-trello:before { content: $fa-var-trello; }
.#{$fa-css-prefix}-female:before { content: $fa-var-female; }
.#{$fa-css-prefix}-male:before { content: $fa-var-male; }
.#{$fa-css-prefix}-gittip:before,
.#{$fa-css-prefix}-gratipay:before { content: $fa-var-gratipay; }
.#{$fa-css-prefix}-sun-o:before { content: $fa-var-sun-o; }
.#{$fa-css-prefix}-moon-o:before { content: $fa-var-moon-o; }
.#{$fa-css-prefix}-archive:before { content: $fa-var-archive; }
.#{$fa-css-prefix}-bug:before { content: $fa-var-bug; }
.#{$fa-css-prefix}-vk:before { content: $fa-var-vk; }
.#{$fa-css-prefix}-weibo:before { content: $fa-var-weibo; }
.#{$fa-css-prefix}-renren:before { content: $fa-var-renren; }
.#{$fa-css-prefix}-pagelines:before { content: $fa-var-pagelines; }
.#{$fa-css-prefix}-stack-exchange:before { content: $fa-var-stack-exchange; }
.#{$fa-css-prefix}-arrow-circle-o-right:before { content: $fa-var-arrow-circle-o-right; }
.#{$fa-css-prefix}-arrow-circle-o-left:before { content: $fa-var-arrow-circle-o-left; }
.#{$fa-css-prefix}-toggle-left:before,
.#{$fa-css-prefix}-caret-square-o-left:before { content: $fa-var-caret-square-o-left; }
.#{$fa-css-prefix}-dot-circle-o:before { content: $fa-var-dot-circle-o; }
.#{$fa-css-prefix}-wheelchair:before { content: $fa-var-wheelchair; }
.#{$fa-css-prefix}-vimeo-square:before { content: $fa-var-vimeo-square; }
.#{$fa-css-prefix}-turkish-lira:before,
.#{$fa-css-prefix}-try:before { content: $fa-var-try; }
.#{$fa-css-prefix}-plus-square-o:before { content: $fa-var-plus-square-o; }
.#{$fa-css-prefix}-space-shuttle:before { content: $fa-var-space-shuttle; }
.#{$fa-css-prefix}-slack:before { content: $fa-var-slack; }
.#{$fa-css-prefix}-envelope-square:before { content: $fa-var-envelope-square; }
.#{$fa-css-prefix}-wordpress:before { content: $fa-var-wordpress; }
.#{$fa-css-prefix}-openid:before { content: $fa-var-openid; }
.#{$fa-css-prefix}-institution:before,
.#{$fa-css-prefix}-bank:before,
.#{$fa-css-prefix}-university:before { content: $fa-var-university; }
.#{$fa-css-prefix}-mortar-board:before,
.#{$fa-css-prefix}-graduation-cap:before { content: $fa-var-graduation-cap; }
.#{$fa-css-prefix}-yahoo:before { content: $fa-var-yahoo; }
.#{$fa-css-prefix}-google:before { content: $fa-var-google; }
.#{$fa-css-prefix}-reddit:before { content: $fa-var-reddit; }
.#{$fa-css-prefix}-reddit-square:before { content: $fa-var-reddit-square; }
.#{$fa-css-prefix}-stumbleupon-circle:before { content: $fa-var-stumbleupon-circle; }
.#{$fa-css-prefix}-stumbleupon:before { content: $fa-var-stumbleupon; }
.#{$fa-css-prefix}-delicious:before { content: $fa-var-delicious; }
.#{$fa-css-prefix}-digg:before { content: $fa-var-digg; }
.#{$fa-css-prefix}-pied-piper-pp:before { content: $fa-var-pied-piper-pp; }
.#{$fa-css-prefix}-pied-piper-alt:before { content: $fa-var-pied-piper-alt; }
.#{$fa-css-prefix}-drupal:before { content: $fa-var-drupal; }
.#{$fa-css-prefix}-joomla:before { content: $fa-var-joomla; }
.#{$fa-css-prefix}-language:before { content: $fa-var-language; }
.#{$fa-css-prefix}-fax:before { content: $fa-var-fax; }
.#{$fa-css-prefix}-building:before { content: $fa-var-building; }
.#{$fa-css-prefix}-child:before { content: $fa-var-child; }
.#{$fa-css-prefix}-paw:before { content: $fa-var-paw; }
.#{$fa-css-prefix}-spoon:before { content: $fa-var-spoon; }
.#{$fa-css-prefix}-cube:before { content: $fa-var-cube; }
.#{$fa-css-prefix}-cubes:before { content: $fa-var-cubes; }
.#{$fa-css-prefix}-behance:before { content: $fa-var-behance; }
.#{$fa-css-prefix}-behance-square:before { content: $fa-var-behance-square; }
.#{$fa-css-prefix}-steam:before { content: $fa-var-steam; }
.#{$fa-css-prefix}-steam-square:before { content: $fa-var-steam-square; }
.#{$fa-css-prefix}-recycle:before { content: $fa-var-recycle; }
.#{$fa-css-prefix}-automobile:before,
.#{$fa-css-prefix}-car:before { content: $fa-var-car; }
.#{$fa-css-prefix}-cab:before,
.#{$fa-css-prefix}-taxi:before { content: $fa-var-taxi; }
.#{$fa-css-prefix}-tree:before { content: $fa-var-tree; }
.#{$fa-css-prefix}-spotify:before { content: $fa-var-spotify; }
.#{$fa-css-prefix}-deviantart:before { content: $fa-var-deviantart; }
.#{$fa-css-prefix}-soundcloud:before { content: $fa-var-soundcloud; }
.#{$fa-css-prefix}-database:before { content: $fa-var-database; }
.#{$fa-css-prefix}-file-pdf-o:before { content: $fa-var-file-pdf-o; }
.#{$fa-css-prefix}-file-word-o:before { content: $fa-var-file-word-o; }
.#{$fa-css-prefix}-file-excel-o:before { content: $fa-var-file-excel-o; }
.#{$fa-css-prefix}-file-powerpoint-o:before { content: $fa-var-file-powerpoint-o; }
.#{$fa-css-prefix}-file-photo-o:before,
.#{$fa-css-prefix}-file-picture-o:before,
.#{$fa-css-prefix}-file-image-o:before { content: $fa-var-file-image-o; }
.#{$fa-css-prefix}-file-zip-o:before,
.#{$fa-css-prefix}-file-archive-o:before { content: $fa-var-file-archive-o; }
.#{$fa-css-prefix}-file-sound-o:before,
.#{$fa-css-prefix}-file-audio-o:before { content: $fa-var-file-audio-o; }
.#{$fa-css-prefix}-file-movie-o:before,
.#{$fa-css-prefix}-file-video-o:before { content: $fa-var-file-video-o; }
.#{$fa-css-prefix}-file-code-o:before { content: $fa-var-file-code-o; }
.#{$fa-css-prefix}-vine:before { content: $fa-var-vine; }
.#{$fa-css-prefix}-codepen:before { content: $fa-var-codepen; }
.#{$fa-css-prefix}-jsfiddle:before { content: $fa-var-jsfiddle; }
.#{$fa-css-prefix}-life-bouy:before,
.#{$fa-css-prefix}-life-buoy:before,
.#{$fa-css-prefix}-life-saver:before,
.#{$fa-css-prefix}-support:before,
.#{$fa-css-prefix}-life-ring:before { content: $fa-var-life-ring; }
.#{$fa-css-prefix}-circle-o-notch:before { content: $fa-var-circle-o-notch; }
.#{$fa-css-prefix}-ra:before,
.#{$fa-css-prefix}-resistance:before,
.#{$fa-css-prefix}-rebel:before { content: $fa-var-rebel; }
.#{$fa-css-prefix}-ge:before,
.#{$fa-css-prefix}-empire:before { content: $fa-var-empire; }
.#{$fa-css-prefix}-git-square:before { content: $fa-var-git-square; }
.#{$fa-css-prefix}-git:before { content: $fa-var-git; }
.#{$fa-css-prefix}-y-combinator-square:before,
.#{$fa-css-prefix}-yc-square:before,
.#{$fa-css-prefix}-hacker-news:before { content: $fa-var-hacker-news; }
.#{$fa-css-prefix}-tencent-weibo:before { content: $fa-var-tencent-weibo; }
.#{$fa-css-prefix}-qq:before { content: $fa-var-qq; }
.#{$fa-css-prefix}-wechat:before,
.#{$fa-css-prefix}-weixin:before { content: $fa-var-weixin; }
.#{$fa-css-prefix}-send:before,
.#{$fa-css-prefix}-paper-plane:before { content: $fa-var-paper-plane; }
.#{$fa-css-prefix}-send-o:before,
.#{$fa-css-prefix}-paper-plane-o:before { content: $fa-var-paper-plane-o; }
.#{$fa-css-prefix}-history:before { content: $fa-var-history; }
.#{$fa-css-prefix}-circle-thin:before { content: $fa-var-circle-thin; }
.#{$fa-css-prefix}-header:before { content: $fa-var-header; }
.#{$fa-css-prefix}-paragraph:before { content: $fa-var-paragraph; }
.#{$fa-css-prefix}-sliders:before { content: $fa-var-sliders; }
.#{$fa-css-prefix}-share-alt:before { content: $fa-var-share-alt; }
.#{$fa-css-prefix}-share-alt-square:before { content: $fa-var-share-alt-square; }
.#{$fa-css-prefix}-bomb:before { content: $fa-var-bomb; }
.#{$fa-css-prefix}-soccer-ball-o:before,
.#{$fa-css-prefix}-futbol-o:before { content: $fa-var-futbol-o; }
.#{$fa-css-prefix}-tty:before { content: $fa-var-tty; }
.#{$fa-css-prefix}-binoculars:before { content: $fa-var-binoculars; }
.#{$fa-css-prefix}-plug:before { content: $fa-var-plug; }
.#{$fa-css-prefix}-slideshare:before { content: $fa-var-slideshare; }
.#{$fa-css-prefix}-twitch:before { content: $fa-var-twitch; }
.#{$fa-css-prefix}-yelp:before { content: $fa-var-yelp; }
.#{$fa-css-prefix}-newspaper-o:before { content: $fa-var-newspaper-o; }
.#{$fa-css-prefix}-wifi:before { content: $fa-var-wifi; }
.#{$fa-css-prefix}-calculator:before { content: $fa-var-calculator; }
.#{$fa-css-prefix}-paypal:before { content: $fa-var-paypal; }
.#{$fa-css-prefix}-google-wallet:before { content: $fa-var-google-wallet; }
.#{$fa-css-prefix}-cc-visa:before { content: $fa-var-cc-visa; }
.#{$fa-css-prefix}-cc-mastercard:before { content: $fa-var-cc-mastercard; }
.#{$fa-css-prefix}-cc-discover:before { content: $fa-var-cc-discover; }
.#{$fa-css-prefix}-cc-amex:before { content: $fa-var-cc-amex; }
.#{$fa-css-prefix}-cc-paypal:before { content: $fa-var-cc-paypal; }
.#{$fa-css-prefix}-cc-stripe:before { content: $fa-var-cc-stripe; }
.#{$fa-css-prefix}-bell-slash:before { content: $fa-var-bell-slash; }
.#{$fa-css-prefix}-bell-slash-o:before { content: $fa-var-bell-slash-o; }
.#{$fa-css-prefix}-trash:before { content: $fa-var-trash; }
.#{$fa-css-prefix}-copyright:before { content: $fa-var-copyright; }
.#{$fa-css-prefix}-at:before { content: $fa-var-at; }
.#{$fa-css-prefix}-eyedropper:before { content: $fa-var-eyedropper; }
.#{$fa-css-prefix}-paint-brush:before { content: $fa-var-paint-brush; }
.#{$fa-css-prefix}-birthday-cake:before { content: $fa-var-birthday-cake; }
.#{$fa-css-prefix}-area-chart:before { content: $fa-var-area-chart; }
.#{$fa-css-prefix}-pie-chart:before { content: $fa-var-pie-chart; }
.#{$fa-css-prefix}-line-chart:before { content: $fa-var-line-chart; }
.#{$fa-css-prefix}-lastfm:before { content: $fa-var-lastfm; }
.#{$fa-css-prefix}-lastfm-square:before { content: $fa-var-lastfm-square; }
.#{$fa-css-prefix}-toggle-off:before { content: $fa-var-toggle-off; }
.#{$fa-css-prefix}-toggle-on:before { content: $fa-var-toggle-on; }
.#{$fa-css-prefix}-bicycle:before { content: $fa-var-bicycle; }
.#{$fa-css-prefix}-bus:before { content: $fa-var-bus; }
.#{$fa-css-prefix}-ioxhost:before { content: $fa-var-ioxhost; }
.#{$fa-css-prefix}-angellist:before { content: $fa-var-angellist; }
.#{$fa-css-prefix}-cc:before { content: $fa-var-cc; }
.#{$fa-css-prefix}-shekel:before,
.#{$fa-css-prefix}-sheqel:before,
.#{$fa-css-prefix}-ils:before { content: $fa-var-ils; }
.#{$fa-css-prefix}-meanpath:before { content: $fa-var-meanpath; }
.#{$fa-css-prefix}-buysellads:before { content: $fa-var-buysellads; }
.#{$fa-css-prefix}-connectdevelop:before { content: $fa-var-connectdevelop; }
.#{$fa-css-prefix}-dashcube:before { content: $fa-var-dashcube; }
.#{$fa-css-prefix}-forumbee:before { content: $fa-var-forumbee; }
.#{$fa-css-prefix}-leanpub:before { content: $fa-var-leanpub; }
.#{$fa-css-prefix}-sellsy:before { content: $fa-var-sellsy; }
.#{$fa-css-prefix}-shirtsinbulk:before { content: $fa-var-shirtsinbulk; }
.#{$fa-css-prefix}-simplybuilt:before { content: $fa-var-simplybuilt; }
.#{$fa-css-prefix}-skyatlas:before { content: $fa-var-skyatlas; }
.#{$fa-css-prefix}-cart-plus:before { content: $fa-var-cart-plus; }
.#{$fa-css-prefix}-cart-arrow-down:before { content: $fa-var-cart-arrow-down; }
.#{$fa-css-prefix}-diamond:before { content: $fa-var-diamond; }
.#{$fa-css-prefix}-ship:before { content: $fa-var-ship; }
.#{$fa-css-prefix}-user-secret:before { content: $fa-var-user-secret; }
.#{$fa-css-prefix}-motorcycle:before { content: $fa-var-motorcycle; }
.#{$fa-css-prefix}-street-view:before { content: $fa-var-street-view; }
.#{$fa-css-prefix}-heartbeat:before { content: $fa-var-heartbeat; }
.#{$fa-css-prefix}-venus:before { content: $fa-var-venus; }
.#{$fa-css-prefix}-mars:before { content: $fa-var-mars; }
.#{$fa-css-prefix}-mercury:before { content: $fa-var-mercury; }
.#{$fa-css-prefix}-intersex:before,
.#{$fa-css-prefix}-transgender:before { content: $fa-var-transgender; }
.#{$fa-css-prefix}-transgender-alt:before { content: $fa-var-transgender-alt; }
.#{$fa-css-prefix}-venus-double:before { content: $fa-var-venus-double; }
.#{$fa-css-prefix}-mars-double:before { content: $fa-var-mars-double; }
.#{$fa-css-prefix}-venus-mars:before { content: $fa-var-venus-mars; }
.#{$fa-css-prefix}-mars-stroke:before { content: $fa-var-mars-stroke; }
.#{$fa-css-prefix}-mars-stroke-v:before { content: $fa-var-mars-stroke-v; }
.#{$fa-css-prefix}-mars-stroke-h:before { content: $fa-var-mars-stroke-h; }
.#{$fa-css-prefix}-neuter:before { content: $fa-var-neuter; }
.#{$fa-css-prefix}-genderless:before { content: $fa-var-genderless; }
.#{$fa-css-prefix}-facebook-official:before { content: $fa-var-facebook-official; }
.#{$fa-css-prefix}-pinterest-p:before { content: $fa-var-pinterest-p; }
.#{$fa-css-prefix}-whatsapp:before { content: $fa-var-whatsapp; }
.#{$fa-css-prefix}-server:before { content: $fa-var-server; }
.#{$fa-css-prefix}-user-plus:before { content: $fa-var-user-plus; }
.#{$fa-css-prefix}-user-times:before { content: $fa-var-user-times; }
.#{$fa-css-prefix}-hotel:before,
.#{$fa-css-prefix}-bed:before { content: $fa-var-bed; }
.#{$fa-css-prefix}-viacoin:before { content: $fa-var-viacoin; }
.#{$fa-css-prefix}-train:before { content: $fa-var-train; }
.#{$fa-css-prefix}-subway:before { content: $fa-var-subway; }
.#{$fa-css-prefix}-medium:before { content: $fa-var-medium; }
.#{$fa-css-prefix}-yc:before,
.#{$fa-css-prefix}-y-combinator:before { content: $fa-var-y-combinator; }
.#{$fa-css-prefix}-optin-monster:before { content: $fa-var-optin-monster; }
.#{$fa-css-prefix}-opencart:before { content: $fa-var-opencart; }
.#{$fa-css-prefix}-expeditedssl:before { content: $fa-var-expeditedssl; }
.#{$fa-css-prefix}-battery-4:before,
.#{$fa-css-prefix}-battery:before,
.#{$fa-css-prefix}-battery-full:before { content: $fa-var-battery-full; }
.#{$fa-css-prefix}-battery-3:before,
.#{$fa-css-prefix}-battery-three-quarters:before { content: $fa-var-battery-three-quarters; }
.#{$fa-css-prefix}-battery-2:before,
.#{$fa-css-prefix}-battery-half:before { content: $fa-var-battery-half; }
.#{$fa-css-prefix}-battery-1:before,
.#{$fa-css-prefix}-battery-quarter:before { content: $fa-var-battery-quarter; }
.#{$fa-css-prefix}-battery-0:before,
.#{$fa-css-prefix}-battery-empty:before { content: $fa-var-battery-empty; }
.#{$fa-css-prefix}-mouse-pointer:before { content: $fa-var-mouse-pointer; }
.#{$fa-css-prefix}-i-cursor:before { content: $fa-var-i-cursor; }
.#{$fa-css-prefix}-object-group:before { content: $fa-var-object-group; }
.#{$fa-css-prefix}-object-ungroup:before { content: $fa-var-object-ungroup; }
.#{$fa-css-prefix}-sticky-note:before { content: $fa-var-sticky-note; }
.#{$fa-css-prefix}-sticky-note-o:before { content: $fa-var-sticky-note-o; }
.#{$fa-css-prefix}-cc-jcb:before { content: $fa-var-cc-jcb; }
.#{$fa-css-prefix}-cc-diners-club:before { content: $fa-var-cc-diners-club; }
.#{$fa-css-prefix}-clone:before { content: $fa-var-clone; }
.#{$fa-css-prefix}-balance-scale:before { content: $fa-var-balance-scale; }
.#{$fa-css-prefix}-hourglass-o:before { content: $fa-var-hourglass-o; }
.#{$fa-css-prefix}-hourglass-1:before,
.#{$fa-css-prefix}-hourglass-start:before { content: $fa-var-hourglass-start; }
.#{$fa-css-prefix}-hourglass-2:before,
.#{$fa-css-prefix}-hourglass-half:before { content: $fa-var-hourglass-half; }
.#{$fa-css-prefix}-hourglass-3:before,
.#{$fa-css-prefix}-hourglass-end:before { content: $fa-var-hourglass-end; }
.#{$fa-css-prefix}-hourglass:before { content: $fa-var-hourglass; }
.#{$fa-css-prefix}-hand-grab-o:before,
.#{$fa-css-prefix}-hand-rock-o:before { content: $fa-var-hand-rock-o; }
.#{$fa-css-prefix}-hand-stop-o:before,
.#{$fa-css-prefix}-hand-paper-o:before { content: $fa-var-hand-paper-o; }
.#{$fa-css-prefix}-hand-scissors-o:before { content: $fa-var-hand-scissors-o; }
.#{$fa-css-prefix}-hand-lizard-o:before { content: $fa-var-hand-lizard-o; }
.#{$fa-css-prefix}-hand-spock-o:before { content: $fa-var-hand-spock-o; }
.#{$fa-css-prefix}-hand-pointer-o:before { content: $fa-var-hand-pointer-o; }
.#{$fa-css-prefix}-hand-peace-o:before { content: $fa-var-hand-peace-o; }
.#{$fa-css-prefix}-trademark:before { content: $fa-var-trademark; }
.#{$fa-css-prefix}-registered:before { content: $fa-var-registered; }
.#{$fa-css-prefix}-creative-commons:before { content: $fa-var-creative-commons; }
.#{$fa-css-prefix}-gg:before { content: $fa-var-gg; }
.#{$fa-css-prefix}-gg-circle:before { content: $fa-var-gg-circle; }
.#{$fa-css-prefix}-tripadvisor:before { content: $fa-var-tripadvisor; }
.#{$fa-css-prefix}-odnoklassniki:before { content: $fa-var-odnoklassniki; }
.#{$fa-css-prefix}-odnoklassniki-square:before { content: $fa-var-odnoklassniki-square; }
.#{$fa-css-prefix}-get-pocket:before { content: $fa-var-get-pocket; }
.#{$fa-css-prefix}-wikipedia-w:before { content: $fa-var-wikipedia-w; }
.#{$fa-css-prefix}-safari:before { content: $fa-var-safari; }
.#{$fa-css-prefix}-chrome:before { content: $fa-var-chrome; }
.#{$fa-css-prefix}-firefox:before { content: $fa-var-firefox; }
.#{$fa-css-prefix}-opera:before { content: $fa-var-opera; }
.#{$fa-css-prefix}-internet-explorer:before { content: $fa-var-internet-explorer; }
.#{$fa-css-prefix}-tv:before,
.#{$fa-css-prefix}-television:before { content: $fa-var-television; }
.#{$fa-css-prefix}-contao:before { content: $fa-var-contao; }
.#{$fa-css-prefix}-500px:before { content: $fa-var-500px; }
.#{$fa-css-prefix}-amazon:before { content: $fa-var-amazon; }
.#{$fa-css-prefix}-calendar-plus-o:before { content: $fa-var-calendar-plus-o; }
.#{$fa-css-prefix}-calendar-minus-o:before { content: $fa-var-calendar-minus-o; }
.#{$fa-css-prefix}-calendar-times-o:before { content: $fa-var-calendar-times-o; }
.#{$fa-css-prefix}-calendar-check-o:before { content: $fa-var-calendar-check-o; }
.#{$fa-css-prefix}-industry:before { content: $fa-var-industry; }
.#{$fa-css-prefix}-map-pin:before { content: $fa-var-map-pin; }
.#{$fa-css-prefix}-map-signs:before { content: $fa-var-map-signs; }
.#{$fa-css-prefix}-map-o:before { content: $fa-var-map-o; }
.#{$fa-css-prefix}-map:before { content: $fa-var-map; }
.#{$fa-css-prefix}-commenting:before { content: $fa-var-commenting; }
.#{$fa-css-prefix}-commenting-o:before { content: $fa-var-commenting-o; }
.#{$fa-css-prefix}-houzz:before { content: $fa-var-houzz; }
.#{$fa-css-prefix}-vimeo:before { content: $fa-var-vimeo; }
.#{$fa-css-prefix}-black-tie:before { content: $fa-var-black-tie; }
.#{$fa-css-prefix}-fonticons:before { content: $fa-var-fonticons; }
.#{$fa-css-prefix}-reddit-alien:before { content: $fa-var-reddit-alien; }
.#{$fa-css-prefix}-edge:before { content: $fa-var-edge; }
.#{$fa-css-prefix}-credit-card-alt:before { content: $fa-var-credit-card-alt; }
.#{$fa-css-prefix}-codiepie:before { content: $fa-var-codiepie; }
.#{$fa-css-prefix}-modx:before { content: $fa-var-modx; }
.#{$fa-css-prefix}-fort-awesome:before { content: $fa-var-fort-awesome; }
.#{$fa-css-prefix}-usb:before { content: $fa-var-usb; }
.#{$fa-css-prefix}-product-hunt:before { content: $fa-var-product-hunt; }
.#{$fa-css-prefix}-mixcloud:before { content: $fa-var-mixcloud; }
.#{$fa-css-prefix}-scribd:before { content: $fa-var-scribd; }
.#{$fa-css-prefix}-pause-circle:before { content: $fa-var-pause-circle; }
.#{$fa-css-prefix}-pause-circle-o:before { content: $fa-var-pause-circle-o; }
.#{$fa-css-prefix}-stop-circle:before { content: $fa-var-stop-circle; }
.#{$fa-css-prefix}-stop-circle-o:before { content: $fa-var-stop-circle-o; }
.#{$fa-css-prefix}-shopping-bag:before { content: $fa-var-shopping-bag; }
.#{$fa-css-prefix}-shopping-basket:before { content: $fa-var-shopping-basket; }
.#{$fa-css-prefix}-hashtag:before { content: $fa-var-hashtag; }
.#{$fa-css-prefix}-bluetooth:before { content: $fa-var-bluetooth; }
.#{$fa-css-prefix}-bluetooth-b:before { content: $fa-var-bluetooth-b; }
.#{$fa-css-prefix}-percent:before { content: $fa-var-percent; }
.#{$fa-css-prefix}-gitlab:before { content: $fa-var-gitlab; }
.#{$fa-css-prefix}-wpbeginner:before { content: $fa-var-wpbeginner; }
.#{$fa-css-prefix}-wpforms:before { content: $fa-var-wpforms; }
.#{$fa-css-prefix}-envira:before { content: $fa-var-envira; }
.#{$fa-css-prefix}-universal-access:before { content: $fa-var-universal-access; }
.#{$fa-css-prefix}-wheelchair-alt:before { content: $fa-var-wheelchair-alt; }
.#{$fa-css-prefix}-question-circle-o:before { content: $fa-var-question-circle-o; }
.#{$fa-css-prefix}-blind:before { content: $fa-var-blind; }
.#{$fa-css-prefix}-audio-description:before { content: $fa-var-audio-description; }
.#{$fa-css-prefix}-volume-control-phone:before { content: $fa-var-volume-control-phone; }
.#{$fa-css-prefix}-braille:before { content: $fa-var-braille; }
.#{$fa-css-prefix}-assistive-listening-systems:before { content: $fa-var-assistive-listening-systems; }
.#{$fa-css-prefix}-asl-interpreting:before,
.#{$fa-css-prefix}-american-sign-language-interpreting:before { content: $fa-var-american-sign-language-interpreting; }
.#{$fa-css-prefix}-deafness:before,
.#{$fa-css-prefix}-hard-of-hearing:before,
.#{$fa-css-prefix}-deaf:before { content: $fa-var-deaf; }
.#{$fa-css-prefix}-glide:before { content: $fa-var-glide; }
.#{$fa-css-prefix}-glide-g:before { content: $fa-var-glide-g; }
.#{$fa-css-prefix}-signing:before,
.#{$fa-css-prefix}-sign-language:before { content: $fa-var-sign-language; }
.#{$fa-css-prefix}-low-vision:before { content: $fa-var-low-vision; }
.#{$fa-css-prefix}-viadeo:before { content: $fa-var-viadeo; }
.#{$fa-css-prefix}-viadeo-square:before { content: $fa-var-viadeo-square; }
.#{$fa-css-prefix}-snapchat:before { content: $fa-var-snapchat; }
.#{$fa-css-prefix}-snapchat-ghost:before { content: $fa-var-snapchat-ghost; }
.#{$fa-css-prefix}-snapchat-square:before { content: $fa-var-snapchat-square; }
.#{$fa-css-prefix}-pied-piper:before { content: $fa-var-pied-piper; }
.#{$fa-css-prefix}-first-order:before { content: $fa-var-first-order; }
.#{$fa-css-prefix}-yoast:before { content: $fa-var-yoast; }
.#{$fa-css-prefix}-themeisle:before { content: $fa-var-themeisle; }
.#{$fa-css-prefix}-google-plus-circle:before,
.#{$fa-css-prefix}-google-plus-official:before { content: $fa-var-google-plus-official; }
.#{$fa-css-prefix}-fa:before,
.#{$fa-css-prefix}-font-awesome:before { content: $fa-var-font-awesome; }
.#{$fa-css-prefix}-handshake-o:before { content: $fa-var-handshake-o; }
.#{$fa-css-prefix}-envelope-open:before { content: $fa-var-envelope-open; }
.#{$fa-css-prefix}-envelope-open-o:before { content: $fa-var-envelope-open-o; }
.#{$fa-css-prefix}-linode:before { content: $fa-var-linode; }
.#{$fa-css-prefix}-address-book:before { content: $fa-var-address-book; }
.#{$fa-css-prefix}-address-book-o:before { content: $fa-var-address-book-o; }
.#{$fa-css-prefix}-vcard:before,
.#{$fa-css-prefix}-address-card:before { content: $fa-var-address-card; }
.#{$fa-css-prefix}-vcard-o:before,
.#{$fa-css-prefix}-address-card-o:before { content: $fa-var-address-card-o; }
.#{$fa-css-prefix}-user-circle:before { content: $fa-var-user-circle; }
.#{$fa-css-prefix}-user-circle-o:before { content: $fa-var-user-circle-o; }
.#{$fa-css-prefix}-user-o:before { content: $fa-var-user-o; }
.#{$fa-css-prefix}-id-badge:before { content: $fa-var-id-badge; }
.#{$fa-css-prefix}-drivers-license:before,
.#{$fa-css-prefix}-id-card:before { content: $fa-var-id-card; }
.#{$fa-css-prefix}-drivers-license-o:before,
.#{$fa-css-prefix}-id-card-o:before { content: $fa-var-id-card-o; }
.#{$fa-css-prefix}-quora:before { content: $fa-var-quora; }
.#{$fa-css-prefix}-free-code-camp:before { content: $fa-var-free-code-camp; }
.#{$fa-css-prefix}-telegram:before { content: $fa-var-telegram; }
.#{$fa-css-prefix}-thermometer-4:before,
.#{$fa-css-prefix}-thermometer:before,
.#{$fa-css-prefix}-thermometer-full:before { content: $fa-var-thermometer-full; }
.#{$fa-css-prefix}-thermometer-3:before,
.#{$fa-css-prefix}-thermometer-three-quarters:before { content: $fa-var-thermometer-three-quarters; }
.#{$fa-css-prefix}-thermometer-2:before,
.#{$fa-css-prefix}-thermometer-half:before { content: $fa-var-thermometer-half; }
.#{$fa-css-prefix}-thermometer-1:before,
.#{$fa-css-prefix}-thermometer-quarter:before { content: $fa-var-thermometer-quarter; }
.#{$fa-css-prefix}-thermometer-0:before,
.#{$fa-css-prefix}-thermometer-empty:before { content: $fa-var-thermometer-empty; }
.#{$fa-css-prefix}-shower:before { content: $fa-var-shower; }
.#{$fa-css-prefix}-bathtub:before,
.#{$fa-css-prefix}-s15:before,
.#{$fa-css-prefix}-bath:before { content: $fa-var-bath; }
.#{$fa-css-prefix}-podcast:before { content: $fa-var-podcast; }
.#{$fa-css-prefix}-window-maximize:before { content: $fa-var-window-maximize; }
.#{$fa-css-prefix}-window-minimize:before { content: $fa-var-window-minimize; }
.#{$fa-css-prefix}-window-restore:before { content: $fa-var-window-restore; }
.#{$fa-css-prefix}-times-rectangle:before,
.#{$fa-css-prefix}-window-close:before { content: $fa-var-window-close; }
.#{$fa-css-prefix}-times-rectangle-o:before,
.#{$fa-css-prefix}-window-close-o:before { content: $fa-var-window-close-o; }
.#{$fa-css-prefix}-bandcamp:before { content: $fa-var-bandcamp; }
.#{$fa-css-prefix}-grav:before { content: $fa-var-grav; }
.#{$fa-css-prefix}-etsy:before { content: $fa-var-etsy; }
.#{$fa-css-prefix}-imdb:before { content: $fa-var-imdb; }
.#{$fa-css-prefix}-ravelry:before { content: $fa-var-ravelry; }
.#{$fa-css-prefix}-eercast:before { content: $fa-var-eercast; }
.#{$fa-css-prefix}-microchip:before { content: $fa-var-microchip; }
.#{$fa-css-prefix}-snowflake-o:before { content: $fa-var-snowflake-o; }
.#{$fa-css-prefix}-superpowers:before { content: $fa-var-superpowers; }
.#{$fa-css-prefix}-wpexplorer:before { content: $fa-var-wpexplorer; }
.#{$fa-css-prefix}-meetup:before { content: $fa-var-meetup; }
