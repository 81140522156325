.cms-home{
	main.main{
		width: 100%;
		.main-cont, .video-cont{
			min-height: 100vh;
		}
	}
	.main-cont{
		padding: 2vw 3vw 1vw;
		background: $white;
		z-index: 3;
		&.checkout{
			min-height: 0 !important;
		}
	}
	header.banner{
		margin-bottom: 30px;
		width: 100%;
		@include media-breakpoint-down(sm){
			text-align: center;
		}
		.logo{
			position: relative;
			top: -4px;
		}
		.primary-nav{
			float: right;
			margin-left: auto;
			@include media-breakpoint-down(sm){
				float: left;
				width: 100%;
			}
		}
		.nav{
			margin-bottom: 0;
			margin-top: 10px;
			list-style: none;
			display: inline-block;
			vertical-align: bottom;
			float: left;
			padding-left: 0;
			@include media-breakpoint-down(sm){
				padding-left: 0;
				float: none;
			}
			li{
				display: inline-block;
				padding-right: 15px;
				&.register{
					padding-right: 0px;
					a{
						padding-right: 15px;
						font-weight: 600;
					}
				}
				a{
					padding: 5px .7rem 0;
					font-weight: 400;
					@include media-breakpoint-down(sm){
						padding: 5px 0;
					}
					&:hover{
						color: $brand-primary;
					}
				}
				&.active{
					a{
						color: rgba($brand-primary,0.5);
					}
				}
			}
		}
		.social{
			margin-bottom: 0;
			list-style: none;
			display: inline-block;
			float: right;
			vertical-align: bottom;
			padding-left: 0;
			@include media-breakpoint-down(sm){
				display: block;
				padding-left: 0;
				float: none;
				width: 100%;
				margin-top: 5px;
			}
			li{
				display: inline-block;
				font-size: 1.5rem;
				a{
					padding: 0 0.7rem;
				}
			}
		}
	}
	.main-cont{
		@extend %transition;
		&.hide{
			@include transform(translateX(-100%));
		}
		.main-content{
			color: #333;
			font-size: 0.95rem;
			width: 100%;
			.inner{
				margin-top: 30px;
				padding-right: 3vw;
			}
			h1{
				font-weight: 300;
			}
			.free-trial{
				@include linear-gradient(to right, darken($brand-primary,10), lighten($brand-primary,10));
				min-width: 200px;
				text-align: center;
			    padding: 0.7rem;
			    margin-top: 22px;
			    font-weight: 500;
			}
		}

		.failureNotification{
			background: $light;
			border-radius: 15px;
			color: $gray;
		    font-weight: 100;
		    font-size: .8rem;
		    display: inline-block;
		    padding: 15px;
		    margin-top: 15px;
			ul{
			    padding-left: 17px;
			    margin-bottom: 0;
			}
		}
		.form-login{
			padding: 3vw 0 0;
			h3{
				color: $brand-primary;
				font-weight: 500;
				font-size: 0.9rem;
			}
			[data-forgot]{
			    padding: 25px;
			    padding-left: 30px;
			    padding-bottom: 0;
			    display: inline-block;
			    font-size: 0.8rem;
			}
			.forgot-password{
				padding-top: 25px;
				.description{
					padding: 10px 0;
					display: block;
					float: left;
					width: 100%;
					font-weight: 600;
					color: $brand-primary;
					&.error{
						color: $oranges;
					}
				}
			}
			.login-btn{
				margin-top: 15px;
				padding: 0.5rem 2rem 0.6rem!important;
				border-radius: 999rem;
				color: $brand-primary;
				text-transform: none;
				
				.rbText{
					position: relative;
					font-weight: 100 !important;
					color: $brand-primary!important;
					font-size: 1rem !important;
				}
				&:hover{
					@include linear-gradient(to right, darken($brand-primary,10), lighten($brand-primary,10));
					color: $white;
					.rbText{
						color: $white !important;
					}
				}
			}
			.password, .username{
				position: relative;
				span{
					position: absolute;
					top: 1px;
					right: 5px;
				}
			}
			.mail-input, .pass-input{
				border: 0;
				background: transparent;
				width: 210px;
				border-bottom: 2px solid $brand-primary;
				@include media-breakpoint-down(sm){
					width: 100%;
				}
				&:focus{
					outline: 0;
				}
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  color: #404040;
				  font-weight: 100;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  color: #404040;
				  font-weight: 100;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				  color: #404040;
				  font-weight: 100;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  color: #404040;
				  font-weight: 100;
				}
			}
			.form-group{
				display: inline-block;
				vertical-align: bottom;
				margin-right: 20px;
				margin-top: 15px;
				margin-bottom: 0;
				@include media-breakpoint-down(md){
					display: block;
				}
			}
		}

	}
	.overlay{
		background: rgba(0,0,0,0.15);
		cursor: pointer;
		z-index: 3;
		@extend %transition;
		@include media-breakpoint-down(md){
			display: block;
			min-height: 300px;
			background-color: $brand-primary;
			background-image: url('https://i.vimeocdn.com/video/678907468_640.webp');
			background-size: cover;
			background-position: 50% 50%;
		}
		&.fadeout{
			width: 100% !important;
			max-width: 100% !important;
			flex: 0 0 100%;
			.play-btn{
				@include opacity(0);
			}
		}
		.play-btn{
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));
			width: 60px;
			height: 60px;
			@include transform-origin(50% 50%);
			@extend %transition;

		}
		svg{
			width: 100%;
			height: 100%;
			@extend %transition;
			path{
				fill: $white;
			}
			circle{
				stroke: $white;
			}
		}
		&:hover{
			background: rgba(0,0,0,0.3);
			.play-btn{
				svg{
					@include transform(scale(1.1,1.1));
				}
			}

		}
	}
	.video-cont{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;

		@include media-breakpoint-down(md){
			display: none;
		}

		video{
		    position: fixed;
		    top: 0;
		    left: 0;
		    bottom: 1px;
		    right: 0;
		    min-width: 100%;
		    min-height: 100%;
		    width: auto;
		    height: auto;
		    z-index: -100;
  			background-size: cover;
	

		}
	}
	.book-column{
		background: $brand-primary;
		@include linear-gradient(45deg, $brand-primary, darken($brand-primary, 15));
		&.fixed-checkout{
			@include media-breakpoint-up(lg){
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
			}
			.inner-order{
				background: $white;
				max-width: 400px;
				text-align: left;
				margin: 0 auto;
				padding: 30px;
				border-radius: 15px;
				box-shadow: 0 14px 34px -10px rgba(0,0,0,.1), 0 14px 34px -10px rgba(0,0,0,.1);
			}
		}
		.inner{
			padding: 50px;
			text-align: center;
			width: 100%;

			img{
				margin: 0 auto;
				display: block;

			}
			.title{
				margin-bottom: 50px;
				font-weight: 100;
				color: $white;
				font-size: 1.5rem;
			}
			.content{
				padding: 30px 15px;
				.price{
					font-weight: 600;
					color: $white;
					font-size: 1.5rem;
					span{
						font-size: 0.9rem;
						font-weight: 100;
						margin-left: 8px;
					}

				}

				.btn-buy{
					background: $white;
					color: $brand-primary;
					text-align: left;
					max-width: 450px;
					display: block;
					margin: 30px auto;
					font-size: 1.5rem;
					font-weight: 100;
					text-transform: none;
					padding: 1.5rem 2.5rem;
					padding-right: 75px;
					@include media-breakpoint-down(md){
						padding: 1rem 2rem;
						font-size: 1.4rem;
					}
					@include media-breakpoint-down(sm){
						padding: 1rem ;
						font-size: 1.2rem;
					}

					svg{
						fill: $brand-primary;
						width: 50px;
						height: 50px;
						@include media-breakpoint-down(md){
							width: 30px;
							height: 30px;
						}
						@include media-breakpoint-down(sm){
							display: none;
						}
					}
					&:hover{
						padding-left: 3rem;
					}

				}

			}

		}
	}
	.contact-section{
		background: $white;
		position: relative;
		z-index: 999;
		display: block;
		width: 100%;

		.inner__txt{
			color: $black;
			font-size: 1.2rem;
			padding:  100px 80px;
			padding-right: 120px;
			h1, h2, h3, h4, h5, h6{
				font-weight: 300;
				font-size: 1.9rem;
			    line-height: 1.5;
			    margin-bottom: 30px;
			}
		}
		.form__inner{
			h1{
				color: $brand-primary;
				margin-bottom: 35px;
				font-size: 1.5rem;
				&.response{
					margin-top: 20px;
				}
			}
		}
		.failureNotification{
			display: block;
		}

		.thanks-contact {
		    padding-top: 118px;
		    font-weight: 100;
		    font-size: 2rem;
		    color: $brand-primary;
		}
		@include m(reg){
			.terms-agree{
				display: inline-block;
				vertical-align: middle;
				font-size: 0.9rem;
			}
			label{
				color: $brand-primary;
			}
			#ddlfld_AgeGroup{
				width: auto;
				min-width: 200px;
			}
			#fld_Gender{
				margin-left: 20px;
				button{
					display: inline-block;
					margin-right: 20px;
					margin-top: 5px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
			button{
				.rbText{
				    position: relative;
				    top: -2px;
				}
			}
		}

	}

	.login-success{
		@include media-breakpoint-down(sm){
			text-align: center;
		}
		h3{
			font-size: 2rem;
			@include media-breakpoint-down(sm){
				font-size: 1.7rem;
			}
		}
		.or{
		    color: $brand-primary;
		    font-weight: 500;
		    font-size: 0.9rem;
		}
		.logout{
			margin-top: 18px;
		}
		.bottom{
			font-size: .95rem;
			margin-top: 20px;
		}
		.block-linksubtop{
			i{
				margin-left: 0;
				margin-right: 15px;
				font-size: 1rem;
				@extend %transition;
			}
			&:hover{
				i{
					margin-right: 20px;
				}
			}
		}
	}

}
footer.content-info{
	padding-top: 50px;
	width: 100%;
	margin-top: auto;
	@include media-breakpoint-down(sm){
		text-align: center;
		border-top: 1px solid lighten($gray, 50);
		padding-top: 30px;
		margin-top: 30px;
	}
	h3{
	    color: $brand-primary;
	    font-weight: 600;
	    font-size: 1rem;
	}
	ul{
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		li{
			display: inline-block;
			margin-right: 15px;
			&:last-child{
				margin-right: 0;
			}
			a{
				display: block;
				border: 1px solid rgba($secondary-color,0.4);
				padding: .5rem 1rem;
				text-align: center;
				font-size: 0.9rem;
				color: $black;
				border-radius: 999rem;
				&:hover{
					background: $brand-primary;
					color: $white;
				}
			}
		}
	}
	.copyright{
		font-size: 0.9rem;
		text-align: right;
		@include media-breakpoint-down(lg){
			text-align: left !important;
			margin-top: 15px;
		}
		@include media-breakpoint-down(sm){
			text-align: center !important;
			margin-bottom: 20px;
		}
		.terms-link{
			display: block;
			font-size: 1rem;
			margin-bottom: 10px;
			@include media-breakpoint-down(lg){
				margin-bottom: 0px;
			}
		}
		&>div{
			width: 100%;
			color: rgba($black,0.5);
		}
	}
}


.access-login{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	li{
		display: inline-block;
		vertical-align: middle;
		margin-right: 12px;
		font-weight: 500;
		color: $brand-primary;
		@include media-breakpoint-down(lg){
			display: block;
		}
		&:last-of-type{
			margin-right: 0;
		}
		img{
			max-width: 25px;
			height: auto;
			margin-right: 10px;
		}
		&.denied{
			color: $oranges;
		}
	}
}

.expiry-access{
	font-weight: 500;
	color: $secondary-color;
	&.expired{
		color: $oranges;
	}
}

.title-block{
    color: $brand-primary;
    font-weight: 600;
    font-size: 1rem;
}