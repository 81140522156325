// BUTTONS
button:focus {
    outline: none!important;
}

.btn {
    font-size: 0.8rem;
    padding: 0.85rem 2.13rem;
    &.btn-lg {
        font-size: 0.9rem;
        padding: 1rem 2.4rem;
    }
    &.btn-md {
        font-size: 0.7rem;
        padding: 0.7rem 1.6rem;
    }
    &.btn-sm {
        font-size: 0.6rem;
        padding:  0.5rem 1.6rem;
        &.btn-table {
            padding: 0.5rem 0.9rem;
        }
        .fa {
            font-size:0.8rem;
        }
    }
}

.btn {
    border-radius: 2px;
    border: 0;
    @extend .z-depth-1;
    transition: .2s ease-out;
    margin: 6px;
    white-space: normal !important;
    word-wrap: break-word;
    text-transform: uppercase;
    &:hover {
        @extend .z-depth-1-half;
        color: #fff;
    }
    &:active,
    &:focus,
    &.active {
        outline: 0;
        color: #fff;
        @extend .z-depth-1-half;
    } 
    .fa {
        font-size: 1.1rem;
        position: relative;
        vertical-align: middle;
        margin-top: -2px;
        &.right {
            margin-left: 3px;
        }
        &.left {
            margin-right: 3px;
        }
    }
}

.btn-group {
    .btn {
        margin: 0;
    }
}

.btn-block {
    margin: inherit;
}

// Alert buttons
.btn-secondary {
    background-color: $secondary-color;
    &:hover,
    &:focus,
    &:active {
        background-color: lighten( $secondary-color, 5%)!important;
        color: #fff;
    }
    &.active {
        background-color: darken( $secondary-color, 20%)!important;
        @extend .z-depth-1-half;
        &:hover {
            color: #fff;
        }
        &:focus {
            color: #fff;
        }
    }
    &.dropdown-toggle {
        color: #fff!important;
        background-color: $secondary-color!important;
        @extend .z-depth-1-half;
        &:hover {
            color: #fff;
            background-color: lighten( $secondary-color, 5%)!important;
        }
        &:focus {
            color: #fff;
            background-color: $secondary-color;
        }
    }
}

.btn-secondary:active:hover {
    background-color: lighten( $secondary-color, 5%)!important;
}

.btn-primary {
    background: $primary-color;
    &:hover,
    &:focus,
    &:active {
        background-color: lighten( $primary-color, 5%)!important;
    }
    &.active {
        background-color: darken( $primary-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}






// Pagination
.pager li a {
    border: 0;
    @extend .z-depth-1;
    transition: .2s ease-out;
    &:hover {
        @extend .z-depth-1-half;
    }
}

.btn-secondary {
    &.disabled,
    &:disabled {
        background-color: #aa66cc;
        cursor: not-allowed;
        pointer-events: auto !important;
    }
}
