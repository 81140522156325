.background-gradmed{
  @include media-breakpoint-down(md){
    display: none;
  }
    &:before, &:after{
      content: '';
      
      z-index: 0;
      bottom: 0;
      right: 0;
      background: $white;
    }
    &:before{
      left: 0;
      top: 0;
      position: fixed;
    }
    &:after{
      position: absolute;
      width: 800px;
      height: 800px;
      background-image: url(../images/background-big.jpg);
      background-size: contain;
      background-position: 50% 50%;
      right: 30%;
      bottom: 30%;
      @include transform(translate(50%,50%));
      @include opacity(0.8);
    }
}
.slider {
  z-index: 8;
  position: relative;
  margin: 75px 0;
}

.block-slide {
    @include e('elem'){
      display: none;
      @include media-breakpoint-up(xl){
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include opacity(0);
        display: block;
      }
      &.is-active{
        z-index: 8;
        display: block;
      }


    }



    @include e('bg'){
      z-index: 7;
      width: 100%;
      @include transform(translateZ(0));
      overflow: hidden;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      overflow: hidden;
      background: $white;
      box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.05);
      @include transition(box-shadow .4s  cubic-bezier(.77,0,.175,1));
      @include media-breakpoint-up(xl){
        position: absolute;
      }
      .velocity-animating & {
        box-shadow: 0px 4px 38px transparent, 0 15px 12px transparent;
        @include opacity(0.75);
      }


    }
}



