$vertical-margin: 12px;
.block-rounded{
	background: $white;
	border-radius: 0.5rem;
	padding: 1.2rem 2rem;
	margin: $vertical-margin 0;
	width: 100%;
	display: block;

	.title{
		font-size: 0.95rem;
		color: darken($secondary-color,10%);
		text-transform: uppercase;
		font-weight: 500;
		margin-bottom: 10px;
	}
}
.block-mock{
	overflow: visible;
	.completetext{
		display: none;
	}
	&.complete{
		pointer-events: none;
		&:before{
			content: '✓';
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			background: $brand-primary;
			color: $white;
			text-align: center;
			font-size: 22px;
			border-radius: 999rem;
			line-height: 40px;
			top: 30px;
			left: 30px;

		}
		&>div{
			@include opacity(0.5);
		}
		.image-container{
			@include opacity(1);
		}
		.completetext{
			font-weight: 600;
			color: $brand-primary;
			position: absolute;
			top: 30px;
			line-height: 40px;
			left: 75px;
			padding-left: 10px;
			display: block;
			@include opacity(1);
		}
	}
}
a.block-rounded{
	@extend %transition;
	&:focus{
		color: $brand-primary;
	}
	&:hover{
		color: $white;
		background: $brand-primary;
	}
}
.block-section{
	width: 100%;
	min-height: 16vw;
	position: relative;
	overflow: hidden;
	@include media-breakpoint-down(md){
		min-height: 100px;
	}
	span{
		font-size: 1.55rem;
		@include media-breakpoint-down(lg){
			font-size: 1.4rem;
		}

	}
	.image-container{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 16vw;
		overflow: hidden;
		@include transform-origin(0 0);
		@include transform(skewX(30deg) translateX(30px));
		@include opacity(0.6);
		@extend %transition;
		@include media-breakpoint-down(lg){
			width: 270px;
		}
		@include media-breakpoint-down(sm){
			width: 200px;
		}
		.img_bg{
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-size: cover;
			background-position: 50% 50%;
			@include transform-origin(0 0);
			@include transform(skewX(-30deg));
		}
	}
	&:hover{
		background: $white !important;
		color: $brand-primary !important;
		.image-container{
			@include opacity(1);
			@include transform(skewX(30deg) translateX(0));
		}
	}
}

.block-analytics{
	width: 100%;
	font-size: 1rem;
	padding: 1.15rem !important;
	@include media-breakpoint-down(lg){

		padding: 1rem !important;
	}
	span, i{
		vertical-align: middle;
		display: inline-block;
		line-height: 3rem;
		@include media-breakpoint-down(lg){
			line-height: 1.4;
		}

	}
	span{
		color: $brand-primary;
		@extend %transition;
		font-weight: 400;
		&.lighter{
			font-weight: 100;
			font-size: 0.9rem;
			margin-left: 10px;
			display: inline-block;
			color: $secondary-color;
		}
	}
	i{
		margin: 0 7px;
		font-size: 1.6rem;
		float: right;
		@include media-breakpoint-down(lg){
			font-size: 1.4rem;
		}
	}
	&:hover{
		span{
			color: $white;
			&.lighter{
				color: rgba($white,0.65);
			}
		}
	}
}

.block-center{
	font-weight: 500;
	text-align: center;
	font-size: 1.15rem;
	color: $brand-primary;
	span, i{
		vertical-align: middle;
		display: inline-block;
	}
	i{
		margin: 0 7px;
	}
}

.block-diagnostic{
	padding: 2rem 2rem;
	background-image: url(../images/background-diagnostic.jpg);
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-size: contain;
	@include media-breakpoint-up(xl){
		.text-small, .title{
			margin-right: 40%;
		}
		.btn{
			margin-top: 40px;
			&.custom{
				margin-top: 0;
				padding: 0.5rem 1.1rem;
				font-size: 0.75rem;
			}
		}
	}
}

.block-results{
	.btn{
		display: inline-block;
	}
}


.section_topics-block{
	h3{
		font-weight: 300;
		font-size: 1.7rem;
		color: $black;
		text-align: center;
		margin: 40px 0 25px;
		@include media-breakpoint-down(lg){
			font-size: 1.4rem;
			margin: 20px 0 15px;
		}
	}
	.tabs{
		list-style: none;
		margin: 10px 0;
		padding: 0 0;
		text-align: center;
		li{
			margin-right: 20px;
			@include media-breakpoint-down(md){
				margin-right: 10px;
				min-width: 100%;
				margin-bottom: 5px;
			}
			&:last-child{
				margin-right: 0;
			}
			a{
				margin: 0;
				width: 100%;
				background: $brand-primary;
				color: $white;
				font-weight: 500;
				text-transform: none;
				font-size: 1.07rem;
				box-shadow: none;
				border-radius: .5rem;
				padding: 1.6rem;
				@include media-breakpoint-down(lg){
					padding: 1rem;
					font-size: 0.9rem;
				}
			}
			&.current{
				a{
					background: $white;
					color: $brand-primary;
					box-shadow: 0 19px 38px rgba(0,0,0,.1), 0 15px 12px rgba(0,0,0,.02);
				}
			}
		}
	}
	.tab_content{
		margin-bottom: 50px;
		.tabs_item{
			display: none;
			background: $white;
			border-radius: 0.5rem;
			padding: 15px;
			box-shadow: 0 19px 38px rgba(0,0,0,.1), 0 15px 12px rgba(0,0,0,.02);
			position: relative;
			z-index: 3;
			&:first-child {
				display: block;
			}
			&.essays{
				.inner_col{
					text-align: left;
					h4{
					    font-size: 0.95rem;
					    color: $secondary-color;
					    text-transform: uppercase;
					    font-weight: 500;
					    margin-bottom: 10px;
					}
					strong{
						font-weight: 600;
					}
				}
			}
		}

	}

	.inner_col{
		text-align: center;
		padding: 40px;
		@include media-breakpoint-down(md){
			padding: 15px;
			overflow: hidden;
		}
		&.sample{
			padding-top: 10px;
			.list_subtopics{
				max-width: 100%;
				margin: 0 0;
				margin-right: 150px;
				@include media-breakpoint-down(md){
					margin-right: 0;
				}
				li{
					a{
						display: block;
						position: relative;
						@include media-breakpoint-down(md){
							border-radius: 10px !important;
						}
					}
					.timesdone{
						position: absolute;
						top: 50%;
						display: block;
						right: -120px;
						width: 100px;
						@include transform(translateY(-50%));
						@include media-breakpoint-down(md){
							position: relative;
							right: 0;
							width: 100%;
							top: 0;
							@include transform(none);
							text-align: left;
							margin: 12px;
						}


						@include e(circle){
							display: inline-block;
							margin-right: 8px;
							width: 30px;
							height: 30px;
							background: $brand-primary;
							font-weight: 500;
							color: $white;
							line-height: 30px;
							text-align: center;
							border-radius: 999rem;
							font-size: 0.85rem;
						}
						@include e(text){
							color: $brand-primary;
							font-weight: 500;
							text-transform: lowercase;
							font-size: 0.9rem;
						}

					}

				}


			}

		}



		h4{
			color: $black;
			font-weight: 500;
			font-size: 1.1rem;
			margin-bottom: 20px;
			i{
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;
			}
		}
		.md-form{
			margin: 0 auto 2rem;
			max-width: 450px;
			.form-control{
				padding: .5rem 0;
				padding-right: 50px;
			}
			i{
				position: absolute;
				right: -36px;
				top: 12px;
				color: $brand-primary;
			}
			label{
				font-weight: 500;
				color: $brand-primary;
			}
		}
		.list_subtopics{
			list-style: none;
			padding-left: 0;
			margin: 0 auto;
			max-width: 450px;

			li{
				margin: 8px 0;
				position: relative;
				@include media-breakpoint-down(md){
					margin: 4px 0;
				}
				a{
					display: block;
					text-align: left;
				    font-size: 1rem;
				    font-weight: 500;
					padding-right: 50px;
					margin: 0;
					i{
						position: absolute;
						top: 50%;
						right: 20px;
						@include transform(translateY(-50%));
						@extend %transition;
					}
					&:hover{
						i{
							right: 25px;
						}
					}
				}
			}
		}
		&.test__generator{
			h4{
				margin-bottom: 20px;
			}
			.desc{
				margin-bottom: 50px;
				color: $secondary-color;
				font-style: italic;
				display: block;
			}
			.btn-primary{
				font-weight: 400;
				text-transform: none;
				min-width: 240px;
			}
			.RadListBox_Material{
				width: 100% !important;
				max-width: 500px;
				height: auto !important;
			}
			.RadListBoxButtonAreaBottom.rlbFixedHeight {
				padding: 0 0;
				.rlbTemplate {
					margin-top: 0 ;
				}
			}
			.rlbButtonAreaBottom{
				@include media-breakpoint-down(md){
					margin: 15px 0 !important;
				}
				ul{
					text-align: center;
				}
			}
			.selectedlist{
				height: auto !important;
				.rlbGroup{
					border-bottom: 0 !important;
				}
				.rlbTemplate{
					position: relative;
					overflow: visible;
				}
				.t-ripple-container{
					display: none;
				}
				.rlbSelected.rlbItem{
					background: $light;
					border-radius: 999rem;
					@extend %clearfix;
				}

				.RadInput {
				    position: absolute;
					right: 0;
					top: -7px;
				}
				.RadInput_Material input.riTextBox{
					border: 0 !important;
					color: $brand-primary;
				}
				.rlbDisabled{
				 	display: none !important;
				}
			}
			.RadListBox_Material .rlbSelected.rlbItem{
				color: $brand-primary;
				font-weight: 500;
			}
			.RadListBox ul{
				padding: 15px;
				@include media-breakpoint-down(md){
					padding: 0;
				}
				li.rlbItem{
					margin: 5px 0;
					padding: .75rem 2rem;
				}
			}
			.RadListBox_Material .rlbButton{
				border-radius: 999rem;
				border: 0;
				color: $white;
				background: $brand-primary;
				cursor: pointer;
				margin: 0 5px;
			}
			.RadListBox_Material .rlbButtonAreaBottom{
				margin-top: 0;
			}
			.RadListBox_Material .rlbHovered.rlbItem{
				border-radius: 999rem;
				color: $black;
				overflow: hidden;
			}
			.RadListBox_Material .rlbGroup{
				font-weight: 400;
				font-size: 1rem;
				color: $black;
				border: 0;
				background: $light;
				border-radius: .5rem;
				width: 100%;
				overflow: hidden;
				display: block;
				margin-right: 0 !important;
			}
		}
	}
}


.block-content__split{
	margin: 15px 0;
	@include media-breakpoint-down(lg){
		margin-bottom: 0;
	}
	.text{
		.inner{
			padding: 1vw;
			padding-right: 2vw;
			@include media-breakpoint-down(lg){
				padding: 0;
			}
			.btn{
				background: $white;
				text-transform: none;
				font-weight: 500;
				padding: .75rem 3rem;
			    font-size: 0.95rem;
			    &:hover, &:focus{
			    	background: $brand-primary;
			    	color: $white;
			    }
			}
		}
	}
	.video{
		@include media-breakpoint-down(lg){
			min-height: 350px;
			padding: 0;
		}
		@include media-breakpoint-down(sm){
			min-height: 250px;
		}
		&>.col{
			@include media-breakpoint-down(lg){
				padding: 0;
			}
		}
		a{
			display: block;
			width: 100%;
			height: 100%;
		}
		.view__video{
			width: 100%;
			.inner{
				background: $white;
				border-radius: 0.7rem;
				overflow: hidden;
				width: 100%;
				position: relative;
				a{
					text-align: center;
					font-weight: 400;
					font-size: 1.5rem;
					span{
						display: block;
						width: 100%;
					}
					&:hover{
						background: $brand-primary;
						color: $white;
					}
				}
			}
		}
		.thumbnail{
			overflow: hidden;
			border-radius: .7rem;
			position: relative;
			width: 100%;
			text-align: center;
			display: block;
			background: $white;
			.RadGauge{
				margin: 20px auto;
			}
		}
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(255,255,255,0.6);
			@extend %transition;
			.play-btn{
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%,-50%));
				width: 60px;
				height: 60px;
				@include transform-origin(50% 50%);
				@extend %transition;
			}
			svg{
				width: 100%;
				height: 100%;
				@extend %transition;
				path{
					fill: $brand-primary;
					@extend %transition;
				}
				circle{
					stroke: $brand-primary;
					@extend %transition;
				}
			}


		}
		img{
			max-height: 380px;
			max-width: 100%;
			height: auto;
			margin: 0 auto;
			@include transform(scale(1.05,1.05));
			@extend %transition;
			&.noscale{
				@include transform(scale(1,1));
			}
		}
		a{
			&:hover{
				img{
					@include transform(none);
				}
				.overlay{
					background: rgba(0,0,0,0.3);
					svg{
						@include transform(scale(1.1,1.1));
						path{
							fill: $white;
						}
						circle{
							stroke: $white;
						}
					}
				}

			}
		}
	}
}

.fullh{
	height: 100%;

}

.header-page{
	margin-top: 15px;
	margin-bottom: 15px;
}

.block-section__head{
    min-height: 200px;
    padding: 20px 40px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(md){
    	min-height: 150px;
    }
	.breadcrumbs{
		&.subtopic{
			.item{
				font-size: 1.3rem;
				border-right: 0;
				position: relative;
				&:after{
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: 1px;
					@include transform(rotate(25deg));
					background: darken($light,10);
					@include media-breakpoint-down(lg){
						display: none;
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
		.item{
			display: inline-block;
			color: $brand-primary;
			font-weight: 100;
			font-size: 1.6rem;
			padding-right: 30px;
			margin-right: 20px;
			@extend %transition;
			border-right: 1px solid darken($light,10);
			@include media-breakpoint-down(lg){
				font-size: 1.4rem;
				display: block;
				border-right: 0;
			}
			&:last-child{
				padding-right: 0;
				margin-right: 0;
				border-right: 0;
			}
			&.active{
				font-weight: 500;

			}
			&.evaluation{
				i{
					font-size: 1rem;
				}
				span{
					margin-left: 8px;
				}
			}
			.icon{
				display: inline-block;
				vertical-align: middle;
				width: 30px;
				height: 30px;
				margin-right: 15px;
				position: relative;
				top: -3px;
				svg{
					width: 100%;
					height: 100%;
					path{
						fill: $brand-primary;
						@extend %transition;
					}
				}
			}
		}
		a.item{
			&:hover{
				color: $secondary-color-light;
				.icon{
					svg{
						path{
							fill: $secondary-color-light;
						}
					}
				}
			}
		}
	}
	.image-container{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40%;
		overflow: hidden;
		@include transform-origin(0 0);
		@include transform(skewX(30deg) translateX(30px));
		.img_bg{
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-size: cover;
			background-position: 50% 50%;
			@include transform-origin(0 0);
			@include transform(skewX(-30deg));
		}
	}
}
.block-linksubtop{
	background: $brand-primary;
	@include linear-gradient(to left, $brand-primary-light, darken($brand-primary,5%));
	color: $white;
	font-size: 1.15rem;
	padding: 20px 20px;
	border-radius: 0.5rem;
	margin: $vertical-margin 0;
	width: 100%;
	display: block;

	i{
		font-size: 1.85rem;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin-left: 12px;
	}
	.faded{
		float: right;
		font-size: 1.2rem;
		line-height: 2;
		@include opacity(0.4);
		@extend %transition;
	}
	&:hover, &:focus{
		color: $white;
		.faded{
			margin-right: 15px;
		}
	}

	@include m(diagnostic){
		background: rgba($secondary-color,0.3);
		color: $brand-primary;
		&:hover, &:focus{
			background: rgba($secondary-color,0.5) !important;
			color: $brand-primary !important;
		}
	}


}



.list__essayrules{
	margin-bottom: 50px;
	@include media-breakpoint-down(md){
		margin-bottom: 20px;
	}
	.blue__block{
		background: $brand-primary;
		@include linear-gradient(to left, $brand-primary-light, darken($brand-primary,5%));
		color: $white;
		font-size: 1.5rem;
		border-radius: 0.5rem;
		margin: $vertical-margin 0;
		width: 100%;
		display: block;
		padding: 10px 40px;
		padding-right: 48px;
		@include media-breakpoint-down(lg){
			padding: 10px 22px;
			padding-right: 48px;
		}

		@include media-breakpoint-down(md){
			margin: $vertical-margin/2 0;
		}
		.go__topic{
			display: block;
			color: $white;
			font-size: 1.2rem;
			padding: 14px 0;
			position: relative;
			@extend %transition;
			@include media-breakpoint-down(md){
				font-size: 1rem;
			}

			i{
				position: absolute;
				top: 17px;
				right: -25px;
				@extend %transition;
				@include media-breakpoint-down(md){
					top: 12px;
				}
			}

		}
		&:hover{
			.go__topic{
				padding: 14px 12px;
				i{
					right: 15px;
				}
			}
		}


		.view__example{
			display: block;
			.go__topic{
				color: $brand-primary;
				display: block;
				&:hover{
					color: $brand-primary;
				}

			}
		}
		&.example{
			background: $white;


		}
	}
	.btn{
		display: block;
		text-align: left;
	    font-size: 1rem;
	    font-weight: 500;
		padding-right: 50px;
		margin: 12px 0;
		background: $white;
		padding: 1rem 2.5rem;
		i{
			position: absolute;
			top: 50%;
			right: 32px;
			@include transform(translateY(-50%));
			@extend %transition;
		}
		&:hover, &:focus{
			background: $white;
			box-shadow: 0 19px 38px rgba(0,0,0,.1), 0 15px 12px rgba(0,0,0,.02);
			color: $brand-primary;
			i{
				right: 40px;
				color: $brand-primary;
			}
		}
	}
	.access{
		padding: 0;
		&>.col-12{
			padding: 0;
		}
	}
}

.block__text{
	font-weight: 400;
}


.question-answers{
	.subject{
		@include media-breakpoint-down(md){
			padding: 10px;
		}
		&:first-of-type{
			.progress-bar-elem{
				.line{
					&>span{
						display: block;

					}

				}
			}
		}
	}
}

.progress-bar-elem{
	width: 100%;
	margin: 20px auto;
	display: block;
	height: 20px;
	border-radius: 999rem;
	background: lighten($secondary-color,25);
	position: relative;
	@include media-breakpoint-down(md){
		margin-top: 50px;
		padding: 0 20px;
	}
	.line{
		&>span{
			display: none;
			position: absolute;
			top: -45px;
			color: $secondary-color;
			font-size: 0.95rem;
			@include transform(translateX(50%));
			@include media-breakpoint-down(md){
				top: -35px;
				font-size: 0.85rem;
				display: block;
			}
		}
		&.poor{
			&>span{
				left: 0%;
			}
		}
		&.average{
			&>span{
				left: 50%;
				@include media-breakpoint-down(md){
					left: 48%;
				}

			}
		}
		&.good{
			&>span{
				left: 75%;
			}
		}


		&.good{
			&:before{
				left: 75%;
			}
		}
		&.average{
			&:before{
				left: 50%;
			}
		}
		&.poor{
			&:before{
				display: none;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: -50px;
			bottom: -55px;
			width: 1px;
			display: block;
			z-index: 0;
			background: lighten($secondary-color,25);
			@include media-breakpoint-down(md){
				top: -15px;
				bottom: -15px;
			}
		}
	}


	.correct{
		width: 0;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		border-radius: 999rem;
		@extend %transition;
		background: $secondary-color;
		z-index: 1;
		&.complete{
			&.low{
				background: #D75725;
			}
			&.mid{
				background: #6CB7DA;
			}
			&.high{
				background: #4a3d90;
			}
		}
	}
}
.diagnostic-intro{
	text-align: center;
	font-size: 1.05rem;
	color: darken($secondary-color,50);
	font-weight: 100;
	.RadGauge{
		margin: 0 auto;
	}
	.title__question{
		color: $black;
	}

}
.results-diagnostic{
	.title__question{
		text-align: center;
	}
}

.result-hidden{
	line-height: 1.8;
	padding: 20px 15px;
	h2{
		color: $brand-primary;
	}
}



.contact-support{
	.failureNotification{
		text-align: left;
	}
	textarea{
		padding-top: 14px;
		min-height: 90px;
	}
	.form-group{
		width: 100%;
	}
	.captcha{
		margin-top: auto;
		&>div{
			&>div{
				&>div{
					float: left;
				}
			}
		}
	}
}


.no-access{
	@include opacity(0.6);
	pointer-events: none !important;
}



.table{
	&.v-middle{
		vertical-align: middle !important;
		td, th{
			vertical-align: middle !important;
		}
	}
}

.title-attempt{
	font-weight: 100;
	font-size: 1.5rem;
}

.mock-section{
	margin-bottom: 12px;
	font-size: 0.9rem;
	b{
		font-size: 1rem;
		font-weight: 600;
	}
	small{
		display: block;
		    font-size: 0.75rem;
		    color: $secondary-color;
	}
	.btn-buy{
		background: $brand-primary;
		color: $white;
		font-size: 0.9rem;
		padding: 0.75rem 1.5rem;
		text-transform: none;
		margin: 3px 0 10px;
		svg{
			top:48%;
			@include transition(margin-right .3s);
		}
		&:hover, &:focus{
			background: $brand-primary;
			color: $white;
			svg{
				margin-right: 5px;
			}
		}
	}
}
