.essay-writing{
	max-width: 1500px;
	margin: 50px auto;
	@include e(area){
		.d-flex{
			width: 100%;
		}
		.textarea-input{
			resize: none;
			outline: 0 !important;
			border: 1px solid rgba($secondary-color,0.3);
			padding: 1rem;
			min-height: 380px;
			border-radius: 0.5rem;
			cursor: pointer;
			@extend %transition;

			&:focus{
				border-color: $secondary-color;
			}
		}
		.actions{
			.btn{
				font-weight: 500;
				text-transform: none;
			}
		}
	}
}
