.sticky-test__header{
	padding: 0;
	&.stuck{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		@include media-breakpoint-down(lg){
			position: relative;
			padding: 0 !important;
		}
	}
	.top-bar{
		background: $brand-primary;
		width: 100%;
		display: block;
		@include linear-gradient(to right, darken($brand-primary,10), lighten($brand-primary,1));
		padding: 22px 40px;
		@include media-breakpoint-down(lg){
			padding: 15px;
		}
		.actions__test{
			display: inline-block;
			float: right;
			vertical-align: middle;
			@include media-breakpoint-down(lg){
				display: block;
				float: none;
			}
		}
		h1{
			display: inline-block;
			float: left;
			vertical-align: middle;
			color: $white;
			font-weight: 100;
			margin-bottom: 0;
			font-size: 1.5rem;
			line-height: 55px;
			@include media-breakpoint-down(lg){
				line-height: 1.5;
				font-size: 1.3rem;
				float: none;
			}
		}
	}
	.bottom-bar{
		width: 100%;
		display: block;
		background: $white;
		padding: 10px 40px;
		text-align: center;
		@include media-breakpoint-down(lg){
			padding: 15px;
			text-align: left;
		}
		.tabs{
			margin: 0 auto;
			max-width: 1400px;
			padding: 0 0;
			list-style: none;
			li{
				display: inline-block;
				a{
					&.btn-tabs{
						background: $light;
						padding: .55rem 1.7rem;
						box-shadow: none;
						min-width: 170px;
						text-align: left;
						font-weight: 500;
						text-transform: none;
						@include media-breakpoint-down(lg){
							padding: 4px 6px;
							border-radius: 3px;
							min-width: 0;
						}
						&:hover, &:focus{
							background: $brand-primary;
							color: $white;
						}
					}
				}
				&.current{
					a{
						&.btn-tabs{
							background: $brand-primary;
							color: $white;
						}
					}
				}
			}
		}
	}
}


.countdown_timing{
	display: inline-block;
	vertical-align: middle;
	.block__time{
		display: inline-block;
		margin: 0 5px;
		text-align: center;
		color: $white;
		span.time{
			font-size: 1.7rem;
			font-weight: 100;
			letter-spacing: 2px;
		}
		span.label{
			display: block;
			font-size: .65rem;
			line-height: 1px;

		}
	}
}

.tab{
	width: 100%;
	@include media-breakpoint-down(lg){
		padding: 0;
	}
}
.pause__button{
	margin: 0 10px;
	width: 42px;
	height: 42px;
	display: inline-block;
	vertical-align: middle;
	background: transparent;
	border: 0;
	outline: 0 !important;
	pointer-events: none;
	position: relative;
	cursor: pointer;
	svg{
		width: 30px;
	    position: absolute;
	    height: 30px;
	    display: block;
	    top: 50%;
	    left: 50%;
	    @include transform(translate(-50%,-50%));
		path{
			fill: $white;
		}
		rect{
			fill: $white;
		}
	}
	.icon_pause{
		svg{
			width: 51px;
			height: 51px;
		}
	}
	.icon_play{
		svg{
			width: 17px;
			height: 17px;
		}
	}
	.icon_pause, .icon_play{
		display: none;
	}
	.icon_clock{
		display: block;
	}
	&.show_pause{
		pointer-events: auto;
		.icon_clock, .icon_play{
			display: none;
		}
		.icon_pause{
			display: block;
		}
	}
	&.show_play{
		pointer-events: auto;
		.icon_clock, .icon_pause{
			display: none;
		}
		.icon_play{
			display: block;
		}

	}
}

.test__container{
	width: 100%;
	.tabs_item{
		display: none;
		max-width:  calculateRem(1300px);
		&:first-child {
			display: block;
		}

	}
}
.question-intro{
	padding: 30px 0;
	font-weight: 400;
	color: #1c1c1c;
	font-weight: 400;
	font-size: 0.9rem;
	.title__question{
		font-weight: 400;
		font-size: 1.5rem;
		margin-bottom: 18px;
		margin-top: 10px;
	}
	.container{
		max-width: 1400px;
	}
	.result__scored{
		font-weight: 600;
		color: $brand-primary;
		font-size: 1.4rem;
		display: block;
		margin-top: 15px;
	}
}
.question-intro, .question-answers{
	margin: 0 auto;


}
.header-inner-questions{
	background:  $white;
	width:  100%;
	border-bottom: 1px solid rgba(0,0,0,0.1);
         padding: 0 15px;
     @include media-breakpoint-up(lg){
     padding: 0 40px

     }

}
.question-answers{
	margin: calculateRem(30px) 0;
	border:  1px solid rgba(0, 0, 0, 0.06);
	background: $white;
	padding: calculateRem(15px);

    position: sticky;
    top: calculateRem(146px);

	.instruction{
		font-weight: 400;
		font-style: italic;
		color: #1c1c1c;
		@include opacity(0.4);
		padding-bottom: 30px;

	}

	.question_part{
		margin-bottom: 10px;

		.title__part{
			font-weight: 500;
			color: $brand-primary;
			margin-bottom: 10px;
			span{
				p:first-child{
					display: inline;
				}
			}
			.number{
				padding-right: 5px;
			}
		}

		.options__answ{
			list-style: none;
			padding-left: 20px;
			li{
				margin: 8px 0;
				color: #1c1c1c;
				font-size: 0.9rem;
				font-weight: 400;
				cursor: pointer;
				position: relative;
				.title{
					padding: 0 11px 0 6px;
				}

				&.selected{
					font-weight: 600;
					color: $oranges;
					img{
						position: absolute;
						width: 25px;
						height: 25px;
						top: 50%;
						@include transform(translateY(-50%));
						left: -35px;
					}
					&.correct{
						.incorrect{
							display: none !important;
						}
					}
				}

				&.correct{
					font-weight: 600;
					color: $brand-primary !important;
					img{
						position: absolute;
						width: 25px;
						height: 25px;
						top: 50%;
						@include transform(translateY(-50%));
						left: -35px;
					}
				}
				&.discarded{
					@include opacity(0.4);
				}
			}
		}
		.answer__result{
			color: $brand-primary;
			display: block;
			background: $light;
			padding: 15px 25px;
			font-size: 0.9rem;
			margin-bottom: 30px;
			border-radius: .5rem;
			font-weight: 400;
			h5{
				margin-bottom: 0;
				font-size: 1rem;
				i{
					font-size: 0.6rem;
					font-weight: 100;
				}
			}
			.why_is{
				display: block;
				font-weight: 600;
			}
		}
	}
	.subject{
		padding: 10px 0;
		[data-toggle]{
			display: block;
			.comments{
				display: inline;
			}
			.title-sub{
				font-weight: 400;
				font-size: 1.5rem;
			}

		}
		.question{
			color: $brand-primary;
			font-weight: 600;
			margin: 10px 0 8px;
			p{
				&:first-child{
					display: inline;
				}
			}
		}
		h5{
			font-size: 0.9rem;
			color: darken($secondary-color,7);
			margin-bottom: 0;
			strong{
				font-weight: 500;
			}
			&.quickmc{
				font-size: 1rem;
				color: $black;
			}
		}
		.btn{
			margin: 0.5rem 0;
			color: $white;
			letter-spacing: 0.03rem;
			&:hover{
				background: $brand-primary;
				color: $white;
			}
		}

	}

	&.columns-layout{
		.columns{
			@include columns(3);
			@include column-gap(2rem);
			&.notlayout{
				@include columns(1);
				@include column-gap(0);
			}
			.subtopic{
				margin-bottom: 20px;
				margin-top: 15px;
				.title-link{
					a{
						position: relative;
						display: inline-block;
						i{
							@extend %transition;
							margin-left: 30px;
							position: relative;
							top: 5px;
						}
						&:hover{
							i{
								margin-left: 38px;
							}
						}
					}
				}
			}
			.element-col{
				@include column-nobreak;
				@include clearfix;
				padding: 5px 0;
				.question{
					font-weight: 500;
					color: $black;
					float: left;
					margin-right: 20px;
					min-width: 100px;
				}
				img{
					float: left;
					width: 25px;
					height: 25px;
					position: relative;
					top: -1px;
				}
			}
		}
	}
}

.sample-gamsat{
	text-align: center;
	color: $brand-primary;
	padding-top: 10px;
	font-weight: 500;
	i{
		margin-left: 10px;
	    position: relative;
	    top: 4px;
	}
}
.gm-select-wrapper {
     user-select: none;
     width: 100%;
     max-width: calculateRem(350px);
	 .gm-select {
	     display: flex;
	     flex-direction: column;
	     border-width: 0 1px 0 1px;
	     border-style: solid;
	     border-color: rgba(0,0,0,0.1);
	     &__trigger{
		     position: relative;
		     display: flex;
		     align-items: center;
		     justify-content: space-between;
		     padding: 0 22px;
		     font-weight: 300;
		     color: #3b3b3b;
		     height: calculateRem(46px);
		     line-height: calculateRem(46px);
		     background: #ffffff;
		     cursor: pointer;

	     }
		.arrow {
		     position: relative;
		     height: 12px;
		     width: 12px;
		}
		 .arrow::before, .arrow::after {
		     content: "";
		     position: absolute;
		     bottom: 0px;
		     width: 0.15rem;
		     height: 100%;
		     transition: all 0.5s;
		}
		 .arrow::before {
		     left: -4px;
		     transform: rotate(45deg);
		     background-color: $brand-primary;
		}
		 .arrow::after {
		     left: 4px;
		     transform: rotate(-45deg);
		     background-color: $brand-primary;
		}

		&.open .arrow::before {
		     left: -4px;
		     transform: rotate(-45deg);
		}
		&.open .arrow::after {
		     left: 5px;
		     transform: rotate(45deg);
		}



	}
}

 .gm-options {
 	overflow-y: auto;
     position: absolute;
     top: 100%;
     left: 0;
     right: 0;
     box-shadow: 0 14px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.15);
     background: #fff;
     transition: all 0.5s;
     opacity: 0;
     visibility: hidden;
     pointer-events: none;
     z-index: 2;
         padding: 15px;
     @include media-breakpoint-up(lg){
     padding: 15px 40px;

     }
}
 .gm-select.open .gm-options {
     opacity: 1;
     visibility: visible;
     pointer-events: all;
}
 .gm-option {
     position: relative;
     display: block;
     padding: 0 calculateRem(15px) 0 calculateRem(15px);
     line-height: calculateRem(46px);
     cursor: pointer;
     transition: all 0.5s;
     border: 1px solid rgba(0,0,0,0.1);
}
 .gm-option:hover {
     cursor: pointer;
     background-color: #f4f4f4;
     border: 1px solid #f4f4f4;
}
 .gm-option.selected {
     color: #ffffff;
     background-color: $brand-primary;
     border: 1px solid $brand-primary;
}

.order-lg-1{
	@include media-breakpoint-up(lg){
		order: 1;
	}
}

.order-lg-2{
	@include media-breakpoint-up(lg){
		order: 2;
	}
}
