.promo-modal{
	.modaal-close {
		position: absolute;
		span{
			@include media-breakpoint-down(sm){
				display: none;
			}
		}
	}
	.modaal-content-container{
		padding: 80px;
		padding-bottom: 110px;
		position: relative;
		overflow: hidden;
		@include media-breakpoint-down(sm){
			padding: 30px;
		}
	}
	.modaal-container{
		max-width: 800px;
		border-radius: 0;
	}

	h3{
		font-weight: 500;
		color: #333;
		font-size: 1rem;
		margin-bottom: 18px;
		position: relative;
		z-index: 1;
	}
	.computer-image{
		display: none;
		
		@include media-breakpoint-up(lg){
			position: absolute;
			top: 50%;
			left: 100%;
			display: block;
			z-index: 0;
			height: 300px;
			width: 70px;
			@include transform(translate(-220px, -50%));
			img{
				width: auto;
				height: 100%;
				max-width: 999999vw;
			}
		}

	}
	.promo-text{
		color: $brand-primary;
		font-weight: 100;
		font-size: 2rem;
		max-width: 500px;
		margin-bottom: 20px;
		z-index: 1;
		position: relative;
		@include media-breakpoint-down(sm){
			font-size: 1.4rem;
			margin-top: 40px;
		}
	}
	.btn{
		border-radius: 999rem;
		font-size: 1.2rem;
		font-weight: 500;
		z-index: 1;
		padding: 0.95rem 2.7rem;
		position: relative;
		@include media-breakpoint-down(sm){
			font-size: 0.9rem;
		}


	}
	.watermark{
		width: 550px;
		height: 550px;
		background-size: contain;
		z-index: 0;
		background-position: 50% 50%;
		position: absolute;
		background-repeat: no-repeat;
		left: 0;
		top:50%;
		@include opacity(0.3);
		@include transform(translate(-278px, -30%));		
		@include media-breakpoint-up(md){
			@include opacity(0.6);
		}
	}
	
}