

// FONT SIZES

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}




$image-path: '../images' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url('#{$image-path}/#{$name}.svg');
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url('#{$image-path}/#{$name}.#{$fallback-extension}');

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url('#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension}');
        }
    }
}




@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}




@mixin columns($args...) {
  -webkit-columns: $args;
  -moz-columns: $args;
  columns: $args;
}



@mixin column-gap($args...) {
  -webkit-column-gap: $args;
  -moz-column-gap: $args;
  column-gap: $args;
}


@mixin column-nobreak {
-webkit-column-break-inside: avoid; 
          page-break-inside: avoid;
               break-inside: avoid; 
}


@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}


@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}
/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

// OPACITY

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// CLEARFIX

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}


@mixin clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}


// usage: @extend %clearfix;

// Visually hide an element

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

// usage @extend %visuallyhidden;


@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin e($element) {
    @include element($element)  {
        @content;
    }
}

@mixin m($modifier) {
    @include modifier($modifier) {
        @content;
    }
}
