.btn{
	display: inline-block;
	cursor: pointer;
	font-family: $primary-font !important;
}
.btn-buy{
	background: $brand-primary;
	@include linear-gradient(45deg, $brand-primary, darken($brand-primary,5));
	color: $white;
	border-radius: 999rem;
	box-shadow: 0;
	position: relative;
	width: 100%;
	padding-right: 70px;
	font-weight: 600;
	svg{
		fill: $white;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		right: 20px;
		@include transform(translateY(-50%));
		display: inline-block;
	}
	&:hover{
		padding-left: 2.2rem;
	}
}
.btn-rounded{
	border-radius: 999rem;
}
.btn-primary{
	background: $brand-primary;
	color: $white;
	&:hover{
		color: $white;
	}
}
.btn-create{
	background: $secondary-color;
}

.btn-secondary{
	background: $light;
	color: $brand-primary;
	&:hover{
		color: $white;
	}
}
.btn.support{
	border: 1px solid $secondary-color-light;
	color: $secondary-color-light;
	background: transparent;
	box-shadow: none !important;
	padding: .5rem 2rem;

	span, i{
		color: $secondary-color-light;
		@extend %transition;
	}
	span{
		text-transform: none;
		font-size: 1.2rem;
		font-weight: 100;
		vertical-align: middle;
	}
	i{
		font-size: 1.15rem;
		margin-right: 10px;
		vertical-align: middle;
	}
	&:hover{
		border: 1px solid $secondary-color;
		span, i{
			color: $secondary-color;
		}
	}
	@include media-breakpoint-down(md){
		padding: .3rem 1rem;
		span{
			font-size: 1rem;
		}
	}

}

.btn-white__test{
	border: 0;
    margin: 6px;
    white-space: normal!important;
    word-wrap: break-word;
	font-family: 'Roboto', sans-serif;
	@include transition(all .45s cubic-bezier(0.3, 0, 0.23, 0.76));
	display: inline-block;
	@extend .btn-rounded;
	background: $white;
	color: $brand-primary;
	text-transform: none;
	padding: 0.75rem 2rem;
	font-weight: 500;
	min-width: 160px;
	font-size: 0.8rem;
	cursor: pointer;
	@extend .hoverable;
	@include media-breakpoint-down(lg){
		min-width: 0;
	}
	&:hover, &:focus{
		text-decoration: none;
		outline: 0;
		background: $light;
		color: $brand-primary;
	}
}


.btn-fade__test{
	@extend .btn-rounded;
	box-shadow: none;
	min-width: 160px;
	background: transparent;
	border: 1px solid rgba(255,255,255,0.5);
	color: rgba(255,255,255,0.5);
	text-transform: none;
	padding: 0.75rem 2rem;
	font-weight: 500;
	&:hover, &:focus{
		border: 1px solid rgba(255,255,255,1);
		color: rgba(255,255,255,1);
		box-shadow: none;
	}
}


.btn-gray{
	@extend .btn-rounded;
	box-shadow: none;
	min-width: 160px;
	border: 0;
	color: $brand-primary;
	background: $light;
	text-transform: none;
	padding: 0.75rem 2rem;
	font-weight: 500;
	&:hover, &:focus{
		color: $white;
		background: $brand-primary;
		box-shadow: none;
	}
}


.btn-evaluate{
	margin-right: 15px;
	background: $secondary-color;
	&:last-child{
		margin-right:0;
	}
	i{
		font-size: 0.85rem;
	}
	span{
		margin-left: 5px;
		font-size: 1.1rem;
		position: relative;
		top: 0px;
		text-transform: none;
	}
	&:nth-child(1){
		background: lighten($secondary-color,15);
	}
	&:nth-child(2){
		background: lighten($secondary-color,10);
	}
	&:nth-child(3){
		background: lighten($secondary-color,5);
	}
	@include m(next){
		background: $white;
		color: $brand-primary;
		span{
			font-size: 1rem !important;
			position: relative;
			top: -1px;
		}
		i{
			position: relative;
			top: 1px;
		}
		&:hover, &:focus{
			background: $white !important;
			color: $brand-primary;
		}
	}
}


// RADIO BUTTONS


@function get($map, $keys...) {
  @each $key in $keys {
		$map: map-get($map, $key);
  }
  @return $map;
}

$input: (
  'size': 16px,
  'margin-right': 7px,
  'color': (
    'checked': $brand-primary,
    'unchecked': $white
  )
);

$ripple: (
  'position': 7px,
  'size': 16px * 1.5,
  'color': rgba($light,0.7)
);

@include keyframes(ripple) {
  5%, 100% { opacity: 0; }
  5% { opacity: 1; }
}


.options__answ{
	input[type="radio"] {
	  display: none;
	}

	input[type="radio"] + label {
	  position: relative;
	  cursor: pointer;
	  padding-left: 23px;
	  margin-bottom: 0;
	  &:before, &:after {
	    content: "";
	    position: absolute;
	    border-radius: 50%;
	    @include transition(all .3s ease);
	  }
	  &:before {
	    top: 3px;
	    left: 2px;
	    border: 1px solid $brand-primary;
	    width: 16px;
	    height: 16px;
	    background: $brand-primary;
	    box-shadow: inset 0 0 0 16px #fff; 
	  }
	  &:after {
	    top: 62%;
	    left: 10px;
	    width: 24px;
	    height: 24px;
	    opacity: 0;
	    background: rgba(248, 249, 250, 0.7);
	    @include transform(translate(-50%, -50%) scale(0));
	  }
	  &.disabled{
	  	pointer-events: none;
	  	@include opacity(0.6);
	  }
	}

	input[type="radio"]:checked + label {
	  &:before {
	    box-shadow: inset 0 0 0 2px #fff; 
	  }
	  &:after {
	    @include transform(translate(-50%, -50%) scale(1));
	    @include animation(ripple 1s none);
	  }
	}
}