$primary-font: 'Roboto', sans-serif;

html, body{
	font-weight: 100;
	font-family: 'Roboto', sans-serif;
}
body{
	overflow-x: hidden;
	color: $black;
}

main.main{
	overflow: hidden;
}

.container{
	position: initial;
}

a, button{
	font-family: 'Roboto', sans-serif;
	@include transition(all .45s cubic-bezier(0.3, 0, 0.23, 0.76));
	&:hover, &:focus{
		text-decoration: none;
		outline: 0;
	}
}

p{
	&:last-of-type{
		margin-bottom: 0;
	}
}

img{
	max-width: 100%;
	height: auto;
}

input, textarea {
    -webkit-border-radius: 0px;
    font-family: $primary-font;
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
   font-family: $primary-font;
}



// TYPES

.title-page{
	font-size: 1.8rem;
	line-height: 4rem;
	color: $black;
	@include media-breakpoint-down(lg){
		font-size: 1.4rem;
		line-height: 3rem;
	}
}

.text-small{
	font-size: 0.8rem;
}


.centertable{
	margin: 0 auto !important;
}


h1,h2,h3,h4,h5,h6{
	font-weight: 300;
}

h1{
	font-size: 2.2rem;
}
h2{
	font-size: 2rem;
}
h3{
	font-size: 1.5rem;
}
h4{
	font-size: 1.3rem;
}
h5{
	font-size: 1.1rem;
}
h6{
	font-size: 1rem;
}