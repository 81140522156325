/** Import Bootstrap functions */
@import "../vendor/bootstrap/scss/functions";



$brand-primary: #4a3d90;
$brand-primary-light: lighten($brand-primary, 5%);
$brand-primary-dark:  darken($brand-primary, 5%);

$secondary-color: #9092ae;
$secondary-color-light: #CFD8DC;
$secondary-color-dark: #607D8B;

$primary-font: 'Roboto', sans-serif;

$black: #333;
$white: #fff;
$light: #f8f9fa;
$gray: #777;
$oranges: #d75725;


$theme-colors: (
  primary: #4a3d90,
  secondary: #9092ae,
);


$fa-font-path:          '~font-awesome/fonts';

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}


@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}


%transition {
  @include transition(all .45s cubic-bezier(0.3, 0, 0.23, 0.76));
}

%transition-slow {
  @include transition(all .9s cubic-bezier(0.3, 0, 0.23, 0.76));
}

%transition-fast {
  @include transition(all .2s cubic-bezier(0.3, 0, 0.23, 0.76));
}


$table-dark-bg: $brand-primary;
$table-dark-border-color: lighten($brand-primary,10)