.cms-dashboard, .cms-admin{
	background: $light;
	main.main{
		padding: 20px;
		overflow: hidden;
		@include media-breakpoint-down(md){
			padding: 0;
		}
	}
	.title-page{
		margin-bottom: 0;
		font-weight: 300;
	}
	.support{
		text-align: right;
		@include media-breakpoint-down(sm){
			text-align: left;
		}
	}
	.header-pagen{
		margin-bottom: 15px;
	}
}

.sidebar-dash{
	background: $white;
	box-shadow: 0 14px 34px -10px rgba(0,0,0,.1), 0 14px 34px -10px rgba(0,0,0,.1);
	@include media-breakpoint-up(lg){
		max-width: 300px;
		min-height: 100vh;
	}
	.overflow-h{
		overlfow: hidden;
	}
	ul{
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
	}
	.head_dash{
		padding: 25px 32px;
		border-bottom: 1px solid $light;
		@include media-breakpoint-down(md){
			padding: 15px;
		}
	}
	.brand{
		display: block;
		text-align: center;
		@include media-breakpoint-down(md){
			text-align: left;
		}
	}
	.user_info{
		margin-top: 40px;
		text-align: center;
		@include media-breakpoint-down(md){
			margin-top: 20px;
		}
		.image_user{
			display: block;
			width: 100px;
			height: 100px;
			margin: 0 auto 10px;
			background: $brand-primary;
			position: relative;
			overflow: hidden;
			border-radius: 999rem;
			@include media-breakpoint-down(md){
				display: none;
			}
			.photo__user{
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-position: 50% 50%;
				background-size: cover;
				z-index: 1;
				border-radius: 999rem;
			}
			.icon{
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 0;
				@include transform(translate(-50%,-50%));
				img{
					width: 80px;
					height: 80px;
				}
			}
		}
		.name_user{
			display: block;
			font-weight: 500;
			color: $brand-primary;
		}
		.code_user{
			font-weight: 100;
			color: rgba($secondary-color-light, 0.8);
			font-size: 0.85rem;
		}
	}
	.nav{
		li{
			position: relative;
			border-bottom: 1px solid $light;
			overflow: hidden;
			&.no-overflow {
				overflow: visible;
			}
			&:last-child{
				border-bottom: 0;
			}
			a{
				color: $secondary-color;
				border-radius: 0;
			    display: block;
			    padding: 1rem 1rem;
			    font-size: 1.12rem;
			    @include media-breakpoint-down(md){
			    	font-size: 0.95rem;
			    	padding: 0.5rem 1rem;
			    }
				i, span{
					display: inline-block;
					vertical-align: top;
				}
				i{
					font-size: 1.25rem;
					margin: 0 20px;
					color: $secondary-color-light;
					position: relative;
					top: 2px;
					@include media-breakpoint-down(md){
						margin-left: 0;
					}
					&.number{
						width: 19px;
						height: 19px;
						border-radius: 999rem;
						background: $secondary-color-light;
						color: $white;
					    text-align: center;
					    font-style: normal;
					    font-size: 12px;
					    font-weight: 600;
					    line-height: 21px;
						font-family: 'Roboto', sans-serif;
					}
				}
				span{
					line-height: 1.5rem;
				}
				.active-arrow{
					position: absolute;
					right: 0;
					@include transform(translateX(50px));
					@extend %transition;
					i{
						margin: 0 15px;
						font-size: 1.5rem;
					}
				}
				&:hover{
					.active-arrow{
						@include transform(none);
					}

				}
			}

			&.active{
				&:before{
					content: '';
					position: absolute;
					display: block;
					top: 0;
					bottom: 0;
					z-index: 3;
					left: 0;
					width: 5px;
					background: $brand-primary;
					@include linear-gradient(to bottom, darken($brand-primary-light,5%), darken($brand-primary,5%));
				}
				a{
					color: $brand-primary;
					font-weight: 500;
					i{
						color: $brand-primary;
					}
					i.number{
						background: $brand-primary;
						color: $white;
					}
					.active-arrow{
						@include transform(none);
					}
				}

			}
			&.logout{
				a{
					font-size: 0.9rem;
				}
			}
			ul.subitem{
				max-height: 0;
				@include transition(max-height .3s ease-in-out);
				li{
					a{
						background: $light;
						.active-arrow{
							display: none;
						}
						i{
							color: $secondary-color;
						}
						&:hover{
							background: lighten($light,4%);
						}
					}
					&.active{
						a{
							i, span{
								color: $brand-primary;
								font-weight: 600;
							}
						}
					}
				}
			}
			&.active{
				ul.subitem{
					max-height: 300px;
					li{
						a{
							color: $secondary-color;
							font-weight: 100;
						}
					}
				}
			}
			ul.submenu-items{
			    position: absolute;
			    background: white;
			    left: 100%;
			    z-index: 999;
			    top: 0;
			    width: calculateRem(300px);
			    @include opacity(0);
			    pointer-events: none;
			    visibility: hidden;
			    border-left: 1px solid rgba(0,0,0,0.05);
			    box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.05);
			    @include media-breakpoint-down(lg){
			    	display: none;
			    	position: relative;
			    	left: 0;
			    	box-shadow: none;
			    	@include opacity(1);
			    	pointer-events: auto;
			    	border-left: 0;
			    	visibility: visible;
			    	width: 100%;
			    }
			    li{
			    	a{
			    		font-size: 0.9rem;
			    		padding: 0.6rem 1rem;
			    		&:hover{
			    			color: $brand-primary;
			    		}
			    	}
			    }
			}
			&:hover{
				ul.submenu-items{
					@include opacity(1);
					visibility: visible;
					pointer-events: auto;

				}
			}
			&.clicked{
				ul.submenu-items{
					@include media-breakpoint-down(lg){
						display: block;
					}
				}
			}

		}
	}
}


.history-table{
	.col{
		width: 25%;
		&:nth-child(1){
			min-width: 150px;
		}
		&.mark{
			width: 15%;
		}
		&.score{
			width: 15%;
		}
		&.submitted{
			width: 40%;
		}
		&.title{
			color: $white;
			text-transform: none;
		}
	}

	.essay{
		position: relative;
		a{
			font-weight: 600;
			&:hover{
				padding-left: 5px;
			}
		}
	}
}

.scroll-chart{
	overflow-y: hidden;
	overflow-x: auto;
	display: block;
	max-width: 800px;
}

.toggle-mobile-nav{
	@include media-breakpoint-up(lg){
		display: block !important;
	}
	@include media-breakpoint-down(md){
		display: none;
	}
}


#menu-button {
  position: absolute;
  top: 28px;
  right: 20px;
  display: block;
  width: 50px;
  height: 50px;
  background: $brand-primary;
  border-radius: 999rem;
  z-index: 200;
  &:after {
    position: absolute;
    content: "☰";
    font-size: 23px;
    color: #FFF;
    width: 48px;
    height: 30px;
    text-align: center;
    line-height: 50px;
  }
  &:hover {
    opacity: .8;
  }
  @include media-breakpoint-up(lg){
  	display: none !important;
  }
}

.t-ie .RadInput_Material, .t-ie .RadInputMgr_Material{
	height: auto !important;
}
